/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { Fragment, useState, useRef } from "react";
import { Popover, Disclosure, Menu, Combobox, Dialog, Transition, Tab } from "@headlessui/react";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingCartIcon,
  UserIcon,
  XMarkIcon,
  DocumentPlusIcon,
  FolderPlusIcon,
  FolderIcon,
  HashtagIcon,
  TagIcon,
  ArrowPathIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  DocumentChartBarIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  EnvelopeIcon,
  ComputerDesktopIcon,
  FaceSmileIcon,
  HomeIcon,
  EyeDropperIcon,
  TvIcon,
  FunnelIcon,
  QrCodeIcon,
  MapPinIcon,
  DocumentCheckIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon, MagnifyingGlassIcon as MagnifyingGlassIconMini, LockClosedIcon, PhoneIcon } from "@heroicons/react/20/solid";
import { Link, useHistory } from "react-router-dom";
import { useKeyPress } from "../components/useKeyPress";
import { isTemplateSpan } from "typescript";

const solutions = [
  {
    name: "Сорсинг",
    description: "Get a better understanding of where your traffic is coming from.",
    href: "#",
    icon: MagnifyingGlassIcon,
  },
  {
    name: "Маркировка",
    description: "Speak directly to your customers in a more meaningful way.",
    href: "#",
    icon: QrCodeIcon,
  },
  { name: "Логистика", description: "Your customers' data will be safe and secure.", href: "#", icon: MapPinIcon },
  {
    name: "Сертификация",
    description: "Connect with third-party tools that you're already using.",
    href: "#",
    icon: ShieldCheckIcon,
  },
  {
    name: "Таможенное оформление",
    description: "Build strategic funnels that will drive your customers to convert",
    href: "#",
    icon: DocumentCheckIcon,
  },
  {
    name: "Отсрочка платежа",
    description: "Get detailed reports that will help you make more informed decisions",
    href: "#",
    icon: ClockIcon,
  },
];

const calalog = [
  {
    name: "Электроника",
    description: "Get a better understanding of where your traffic is coming from.",
    href: "/",
    icon: TvIcon,
  },
  {
    name: "Игрушки",
    description: "Speak directly to your customers in a more meaningful way.",
    href: "/kids",
    icon: FaceSmileIcon,
  },
  { name: "Животным", description: "Your customers' data will be safe and secure.", href: "/", icon: FunnelIcon },
  {
    name: "Для дома",
    description: "Большой выбор практичных, качественных и стильных товаров для дома и предметов интерьера.",
    href: "/",
    icon: HomeIcon,
  },
  {
    name: "Красота",
    description: "Широкий ассортимент классических ароматов и эксклюзивных новинок безупречного качества от топовых брендов.",
    href: "/",
    icon: EyeDropperIcon,
  },
];

const callsToAction = [{ name: "Оформить заказ", href: "/cart", icon: EnvelopeIcon }];

const currencies = ["RUB", "KZT", "TL"];
const navigation = {
  categories: [],
  pages: [
    // { name: "Товары", href: "/product" },
    { name: "Новинки", href: "/new" },
    { name: "FAQ", href: "/faq" },

    { name: "Контакты", href: "/contact" },
    // { name: "Статус заказа", href: "/status" },
  ],
};
const offers = [
  { name: "Download the app", description: "Get an exclusive $5 off code", href: "#" },
  { name: "Return when you're ready", description: "60 days of free returns", href: "#" },
  { name: "Sign up for our newsletter", description: "15% off your first order", href: "#" },
];
const trendingProducts = [
  {
    id: 1,
    name: "Machined Pen",
    color: "Black",
    price: "$35",
    href: "#",
    imageSrc: "https://tailwindui.com/img/ecommerce-images/home-page-02-product-01.jpg",
    imageAlt: "Black machined steel pen with hexagonal grip and small white logo at top.",
    availableColors: [
      { name: "Black", colorBg: "#111827" },
      { name: "Brass", colorBg: "#FDE68A" },
      { name: "Chrome", colorBg: "#E5E7EB" },
    ],
  },
  // More products...
];
const collections = [
  {
    name: "Desk and Office",
    description: "Work from home accessories",
    imageSrc: "https://tailwindui.com/img/ecommerce-images/home-page-02-edition-01.jpg",
    imageAlt: "Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.",
    href: "#",
  },
  {
    name: "Self-Improvement",
    description: "Journals and note-taking",
    imageSrc: "https://tailwindui.com/img/ecommerce-images/home-page-02-edition-02.jpg",
    imageAlt: "Wood table with porcelain mug, leather journal, brass pen, leather key ring, and a houseplant.",
    href: "#",
  },
  {
    name: "Travel",
    description: "Daily commute essentials",
    imageSrc: "https://tailwindui.com/img/ecommerce-images/home-page-02-edition-03.jpg",
    imageAlt: "Collection of four insulated travel bottles on wooden shelf.",
    href: "#",
  },
];
const testimonials = [
  {
    id: 1,
    quote: "My order arrived super quickly. The product is even better than I hoped it would be. Very happy customer over here!",
    attribution: "Sarah Peters, New Orleans",
  },
  {
    id: 2,
    quote: "I had to return a purchase that didn’t fit. The whole process was so simple that I ended up ordering two new items!",
    attribution: "Kelly McPherson, Chicago",
  },
  {
    id: 3,
    quote:
      "Now that I’m on holiday for the summer, I’ll probably order a few more shirts. It’s just so convenient, and I know the quality will always be there.",
    attribution: "Chris Paul, Phoenix",
  },
];
const footerNavigation = {
  products: [
    { name: "Bags", href: "#" },
    { name: "Tees", href: "#" },
    { name: "Objects", href: "#" },
    { name: "Home Goods", href: "#" },
    { name: "Accessories", href: "#" },
  ],
  customerService: [
    { name: "Contact", href: "#" },
    { name: "Shipping", href: "#" },
    { name: "Returns", href: "#" },
    { name: "Warranty", href: "#" },
    { name: "Secure Payments", href: "#" },
    { name: "FAQ", href: "#" },
    { name: "Find a store", href: "#" },
  ],
  company: [
    { name: "Who we are", href: "#" },
    { name: "Sustainability", href: "#" },
    { name: "Press", href: "#" },
    { name: "Careers", href: "#" },
    { name: "Terms & Conditions", href: "#" },
    { name: "Privacy", href: "#" },
  ],
  legal: [
    { name: "Terms of Service", href: "#" },
    { name: "Return Policy", href: "#" },
    { name: "Privacy Policy", href: "#" },
    { name: "Shipping Policy", href: "#" },
  ],
  bottomLinks: [
    { name: "Accessibility", href: "#" },
    { name: "Privacy", href: "#" },
    { name: "Terms", href: "#" },
  ],
};

const projects = [
  { id: 1, name: "Workflow Inc. / Website Redesign", url: "#" },
  // More projects...
];
const recent = [projects[0]];
const quickActions = [
  { name: "Add new file...", icon: DocumentPlusIcon, shortcut: "N", url: "#" },
  { name: "Add new folder...", icon: FolderPlusIcon, shortcut: "F", url: "#" },
  { name: "Add hashtag...", icon: HashtagIcon, shortcut: "H", url: "#" },
  { name: "Add label...", icon: TagIcon, shortcut: "L", url: "#" },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(true);
  const STEP = 20;

  const inputEl = useRef<HTMLInputElement>(null);

  const history = useHistory();

  const onKeyPress = (event: KeyboardEvent) => {
    // check if the Shift key is pressed
    if (event.metaKey === true) {
      setOpen(true);
    }
  };

  useKeyPress(["k"], onKeyPress);

  const filteredProjects =
    query === ""
      ? []
      : projects.filter((project) => {
          return project.name.toLowerCase().includes(query.toLowerCase());
        });

  const show = () => {
    setOpen(true);

    if (inputEl.current) {
      inputEl.current.blur();
    }
  };

  const hide = () => {
    setOpen(false);
  };

  const onNews = () => {
    let newSortOptions = JSON.parse(
      window.localStorage.sortOptions ??
        JSON.stringify([
          { name: "Рекомендации", value: "Рекомендации", current: true },
          { name: "Новинки", value: "Новинки", current: false },
          { name: "По названию", value: "По названию", current: false },
          { name: "По серии", value: "Серия", current: false },
          { name: "По артикулу", value: "Артикул", current: false },
        ])
    );

    newSortOptions.forEach((o: any) => {
      if (o.name === "Новинки") {
        o.current = true;
      } else {
        o.current = false;
      }
    });

    try {
      window.localStorage.setItem("sortOptions", JSON.stringify(newSortOptions));
    } catch {}

    try {
      window.localStorage.setItem("limit", String(STEP));
    } catch {}

    history.push("/new");
  };

  return (
    <>
      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileMenuOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pt-5 pb-2">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-slate-400"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Links */}
                <Tab.Group as="div" className="mt-2">
                  <div className="border-b border-slate-200">
                    <Tab.List className="-mb-px flex space-x-8 px-4">
                      {navigation.categories.length > 0 &&
                        navigation.categories.map((category: any) => (
                          <Tab
                            key={category.name}
                            className={({ selected }) =>
                              classNames(
                                selected ? "border-emerald-400 text-emerald-400" : "border-transparent text-slate-900",
                                "flex-1 whitespace-nowrap border-b-2 py-4 px-1 text-base font-medium"
                              )
                            }
                          >
                            {category.name}
                          </Tab>
                        ))}
                    </Tab.List>
                  </div>
                  <Tab.Panels as={Fragment}>
                    {navigation.categories.length > 0 &&
                      navigation.categories.map((category: any, categoryIdx) => (
                        <Tab.Panel key={category.name} className="space-y-12 px-4 pt-10 pb-6">
                          <div className="grid grid-cols-1 items-start gap-y-10 gap-x-6">
                            <div className="grid grid-cols-1 gap-y-10 gap-x-6">
                              <div>
                                <p id={`mobile-featured-heading-${categoryIdx}`} className="font-medium text-slate-900">
                                  Featured
                                </p>
                                <ul role="list" aria-labelledby={`mobile-featured-heading-${categoryIdx}`} className="mt-6 space-y-6">
                                  {category.featured.length > 0 &&
                                    category.featured.map((item: any) => (
                                      <li key={item.name} className="flex">
                                        <a href={item.href} className="text-slate-500">
                                          {item.name}
                                        </a>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                              <div>
                                <p id="mobile-categories-heading" className="font-medium text-slate-900">
                                  Categories
                                </p>
                                <ul role="list" aria-labelledby="mobile-categories-heading" className="mt-6 space-y-6">
                                  {category.categories.length > 0 &&
                                    category.categories.map((item: any) => (
                                      <li key={item.name} className="flex">
                                        <a href={item.href} className="text-slate-500">
                                          {item.name}
                                        </a>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </div>
                            <div className="grid grid-cols-1 gap-y-10 gap-x-6">
                              <div>
                                <p id="mobile-collection-heading" className="font-medium text-slate-900">
                                  Collection
                                </p>
                                <ul role="list" aria-labelledby="mobile-collection-heading" className="mt-6 space-y-6">
                                  {category.collection.length > 0 &&
                                    category.collection.map((item: any) => (
                                      <li key={item.name} className="flex">
                                        <a href={item.href} className="text-slate-500">
                                          {item.name}
                                        </a>
                                      </li>
                                    ))}
                                </ul>
                              </div>

                              <div>
                                <p id="mobile-brand-heading" className="font-medium text-slate-900">
                                  Brands
                                </p>
                                <ul role="list" aria-labelledby="mobile-brand-heading" className="mt-6 space-y-6">
                                  {category.brands.length > 0 &&
                                    category.brands.map((item: any) => (
                                      <li key={item.name} className="flex">
                                        <a href={item.href} className="text-slate-500">
                                          {item.name}
                                        </a>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </Tab.Panel>
                      ))}
                  </Tab.Panels>
                </Tab.Group>

                <div className="space-y-6 border-t border-slate-200 py-6 px-4">
                  <div className="flow-root">
                    <button type="button" onClick={onNews} className="-m-2 block p-2 font-medium text-slate-900">
                      Новинки
                    </button>
                  </div>
                  <div className="flow-root">
                    <Link to="/faq" className="-m-2 block p-2 font-medium text-slate-900">
                      FAQ
                    </Link>
                  </div>
                  <div className="flow-root">
                    <Link to="/contact" className="-m-2 block p-2 font-medium text-slate-900">
                      Контакты
                    </Link>
                  </div>
                </div>

                {/* <div className="space-y-6 border-t border-slate-200 py-6 px-4">
                    <div className="flow-root">
                      <Link to="/checkout" className="-m-2 block p-2 font-medium text-slate-900">
                        Create an account
                      </Link>
                    </div>
                    <div className="flow-root">
                      <Link to="/checkout" className="-m-2 block p-2 font-medium text-slate-900">
                        Sign in
                      </Link>
                    </div>
                  </div> */}

                <div className="space-y-6 border-t border-slate-200 py-6 px-4">
                  {/* Currency selector */}
                  {/* <form>
                      <div className="inline-block">
                        <label htmlFor="mobile-currency" className="sr-only">
                          Currency
                        </label>
                        <div className="group relative -ml-2 rounded-md border-transparent focus-within:ring-2 focus-within:ring-white">
                          <select
                            id="mobile-currency"
                            name="currency"
                            className="flex items-center rounded-md border-transparent bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-slate-700 focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-slate-800"
                          >
                            {currencies.map((currency) => (
                              <option key={currency}>{currency}</option>
                            ))}
                          </select>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                            <ChevronDownIcon className="h-5 w-5 text-slate-500" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </form> */}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Top navigation */}
      <div className="z-10 bg-slate-900">
        <div className="mx-auto flex h-10 max-w-7xl items-center justify-center">
          {/* Currency selector */}
          {/* <form className="hidden lg:block lg:flex-1">
            <div className="flex">
              <label htmlFor="desktop-currency" className="sr-only">
                Currency
              </label>
              <div className="group relative -ml-2 rounded-md border-transparent bg-slate-900 focus-within:ring-2 focus-within:ring-white">
                <select
                  id="desktop-currency"
                  name="currency"
                  className="flex items-center rounded-md border-transparent bg-slate-900 bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-white focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-slate-100"
                >
                  {currencies.map((currency) => (
                    <option key={currency}>{currency}</option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                  <ChevronDownIcon className="h-5 w-5 text-slate-300" aria-hidden="true" />
                </div>
              </div>
            </div>
          </form> */}

          <p className="flex-1 text-center text-sm font-medium text-white lg:flex-none">
            Новая коллекция LEGO уже на складе{" "}
            <Link to="/new" className=" text-white underline">
              Посмотреть
              <span aria-hidden="true"> &rarr;</span>
            </Link>
          </p>

          <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
            <a className="flex items-center gap-2 text-sm font-medium text-white hover:text-slate-100" href="tel:+74958881367">
              <PhoneIcon className="h-5 w-4 text-white" aria-hidden="true" />
              <span>8 495 888-13-67</span>
            </a>
            <a className="flex items-center gap-2 text-sm font-medium text-white hover:text-slate-100" href="tel:+74958884062">
              <PhoneIcon className="h-5 w-4 text-white" aria-hidden="true" />
              <span>8 495 888-40-62</span>
            </a>
            <span className="h-6 w-px bg-slate-600" aria-hidden="true" />
            <Link to="/contact" className="text-sm font-medium text-white hover:text-slate-100">
              Связаться с нами
            </Link>
          </div>
        </div>
      </div>

      <header className="js-f-header hide-nav js-hide-nav  js-hide-nav--main relative z-2">
        <nav aria-label="Top">
          {/* Secondary navigation */}
          <div className=" bg-white">
            <div className="border-b border-slate-200">
              <div className="mx-auto max-w-7xl px-4 sm:px-0">
                <div className="flex h-16 w-full items-center justify-between">
                  {/* Logo (lg+) */}
                  <div className="hidden lg:flex lg:items-center">
                    <Link to="/" className="flex items-center justify-center gap-2">
                      <img className="h-8 w-auto" src="/assets/img/logo-emerald-400.svg" alt="" />
                      <p className="uppercase">
                        <span className="font-mono italic">Fast</span>
                        <span className="font-extrabold">Track</span>
                      </p>
                    </Link>
                  </div>

                  <Popover className="relative hidden md:block">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={classNames(
                            open ? " text-emerald-400" : "text-slate-700 hover:text-slate-800",
                            "relative z-10 -mb-px ml-8 flex items-center pt-px text-sm font-medium transition-colors duration-200 ease-out focus:outline-none"
                          )}
                        >
                          <span>Решения</span>
                          <ChevronDownIcon
                            className={classNames(
                              open ? " text-emerald-400" : "text-slate-700 hover:text-slate-800",
                              "ml-2 h-5 w-5 transition duration-150 ease-in-out focus:outline-none group-hover:text-slate-500"
                            )}
                            aria-hidden="true"
                          />
                        </Popover.Button>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute z-10 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:max-w-3xl">
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                                {solutions.map((item) => (
                                  <a
                                    key={item.name}
                                    href={item.href}
                                    className="-m-3 flex items-start rounded-lg p-3 transition duration-150 ease-in-out hover:bg-slate-50"
                                  >
                                    <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-emerald-400 text-white sm:h-12 sm:w-12">
                                      <item.icon className="h-6 w-6" aria-hidden="true" />
                                    </div>
                                    <div className="ml-4">
                                      <p className="text-base font-medium text-slate-900">{item.name}</p>
                                      <p className="mt-1 text-sm text-slate-500">{item.description}</p>
                                    </div>
                                  </a>
                                ))}
                              </div>
                              <div className="space-y-6 bg-slate-50 px-5 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                                {callsToAction.map((item) => (
                                  <div key={item.name} className="flow-root">
                                    <Link
                                      to={item.href}
                                      className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-slate-900 transition duration-150 ease-in-out hover:bg-slate-100"
                                    >
                                      <item.icon className="h-6 w-6 flex-shrink-0 text-slate-400" aria-hidden="true" />
                                      <span className="ml-3">{item.name}</span>
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>

                  <Popover className="relative hidden md:block">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={classNames(
                            open ? " text-emerald-400" : "text-slate-700 hover:text-slate-800",
                            "relative z-10 -mb-px ml-8 flex items-center pt-px text-sm font-medium transition-colors duration-200 ease-out focus:outline-none"
                          )}
                        >
                          <span>Каталог</span>
                          <ChevronDownIcon
                            className={classNames(
                              open ? " text-emerald-400" : "text-slate-700 hover:text-slate-800",
                              "ml-2 h-5 w-5 transition duration-150 ease-in-out focus:outline-none group-hover:text-slate-500"
                            )}
                            aria-hidden="true"
                          />
                        </Popover.Button>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform px-2 sm:px-0">
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                {calalog.map((item) => (
                                  <Link
                                    key={item.name}
                                    to={item.href}
                                    className="-m-3 flex items-start rounded-lg p-3 transition duration-150 ease-in-out hover:bg-slate-50"
                                  >
                                    <item.icon className="h-6 w-6 flex-shrink-0 text-emerald-400" aria-hidden="true" />
                                    <div className="ml-4">
                                      <p className="flex items-center justify-start text-base font-medium text-slate-900">
                                        {item.name}{" "}
                                        {item.name !== "Игрушки" ? (
                                          <LockClosedIcon className="ml-2 h-5 w-5 flex-shrink-0 text-slate-900/20" aria-hidden="true" />
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                      <p className="mt-1 text-sm text-slate-500">{item.description}</p>
                                    </div>
                                  </Link>
                                ))}
                              </div>
                              <div className="space-y-6 bg-slate-50 px-5 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                                {callsToAction.map((item) => (
                                  <div key={item.name} className="flow-root">
                                    <Link
                                      to={item.href}
                                      className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-slate-900 transition duration-150 ease-in-out hover:bg-slate-100"
                                    >
                                      <item.icon className="h-6 w-6 flex-shrink-0 text-slate-400" aria-hidden="true" />
                                      <span className="ml-3">{item.name}</span>
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>

                  <div className="hidden h-full lg:flex">
                    {/* Mega menus */}
                    <Popover.Group className="ml-8">
                      <div className="flex h-full justify-center space-x-8">
                        {navigation.categories.length > 0 &&
                          navigation.categories.map((category: any, categoryIdx) => (
                            <Popover key={category.name} className="flex">
                              {({ open }) => (
                                <>
                                  <div className="relative flex">
                                    <Popover.Button
                                      className={classNames(
                                        open ? "border-emerald-400 text-emerald-400" : "border-transparent text-slate-700 hover:text-slate-800",
                                        "relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out focus:outline-none"
                                      )}
                                    >
                                      {category.name}
                                    </Popover.Button>
                                  </div>

                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Popover.Panel className="absolute inset-x-0 top-full text-slate-500 sm:text-sm">
                                      {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                                      <div className="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true" />

                                      <div className="relative bg-white">
                                        <div className="mx-auto max-w-7xl px-8">
                                          <div className="grid grid-cols-2 items-start gap-y-10 gap-x-8 pt-10 pb-12">
                                            <div className="grid grid-cols-2 gap-y-10 gap-x-8">
                                              <div>
                                                <p id={`desktop-featured-heading-${categoryIdx}`} className="font-medium text-slate-900">
                                                  Категории
                                                </p>
                                                <ul
                                                  role="list"
                                                  aria-labelledby={`desktop-featured-heading-${categoryIdx}`}
                                                  className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                                >
                                                  {category.featured.map((item: any) => (
                                                    <li key={item.name} className="flex">
                                                      <Link to={item.href} className="hover:text-slate-800">
                                                        {item.name}
                                                      </Link>
                                                    </li>
                                                  ))}
                                                </ul>
                                              </div>
                                              <div>
                                                <p id="desktop-categories-heading" className="font-medium text-slate-900">
                                                  Categories
                                                </p>
                                                <ul role="list" aria-labelledby="desktop-categories-heading" className="mt-6 space-y-6 sm:mt-4 sm:space-y-4">
                                                  {category.categories.map((item: any) => (
                                                    <li key={item.name} className="flex">
                                                      <Link to={item.href} className="hover:text-slate-800">
                                                        {item.name}
                                                      </Link>
                                                    </li>
                                                  ))}
                                                </ul>
                                              </div>
                                            </div>
                                            <div className="grid grid-cols-2 gap-y-10 gap-x-8">
                                              <div>
                                                <p id="desktop-collection-heading" className="font-medium text-slate-900">
                                                  Collection
                                                </p>
                                                <ul role="list" aria-labelledby="desktop-collection-heading" className="mt-6 space-y-6 sm:mt-4 sm:space-y-4">
                                                  {category.collection.map((item: any) => (
                                                    <li key={item.name} className="flex">
                                                      <Link to={item.href} className="hover:text-slate-800">
                                                        {item.name}
                                                      </Link>
                                                    </li>
                                                  ))}
                                                </ul>
                                              </div>

                                              <div>
                                                <p id="desktop-brand-heading" className="font-medium text-slate-900">
                                                  Brands
                                                </p>
                                                <ul role="list" aria-labelledby="desktop-brand-heading" className="mt-6 space-y-6 sm:mt-4 sm:space-y-4">
                                                  {category.brands.map((item: any) => (
                                                    <li key={item.name} className="flex">
                                                      <Link to={item.href} className="hover:text-slate-800">
                                                        {item.name}
                                                      </Link>
                                                    </li>
                                                  ))}
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Popover.Panel>
                                  </Transition>
                                </>
                              )}
                            </Popover>
                          ))}

                        <button type="button" onClick={onNews} className="flex items-center text-sm font-medium text-slate-700 hover:text-slate-800">
                          Новинки
                        </button>
                        <Link to="/faq" className="flex items-center text-sm font-medium text-slate-700 hover:text-slate-800">
                          FAQ
                        </Link>
                        <Link to="/contact" className="flex items-center text-sm font-medium text-slate-700 hover:text-slate-800">
                          Контакты
                        </Link>
                      </div>
                    </Popover.Group>
                  </div>

                  {/* Mobile menu and search (lg-) */}
                  <div className="flex flex-1 items-center lg:hidden">
                    <button type="button" className="-ml-2 rounded-md bg-white p-2 text-slate-400" onClick={() => setMobileMenuOpen(true)}>
                      <span className="sr-only">Open menu</span>
                      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    {/* Search */}
                    {/* <span className="ml-2 cursor-pointer p-2 text-slate-400 hover:text-slate-500" onClick={() => show()}>
                      <span className="sr-only">Поиск</span>
                      <MagnifyingGlassIcon className="h-6 w-6" aria-hidden="true" />
                    </span> */}
                  </div>

                  {/* Logo (lg-) */}
                  <Link to="/" className="lg:hidden">
                    <span className="sr-only">Your Company</span>
                    <img src="/assets/img/logo-emerald-400.svg" alt="" className="h-8 w-auto" />
                  </Link>

                  <div className="flex flex-1 items-center justify-end">
                    <div className="flex items-center justify-end lg:ml-8">
                      <div className="flex space-x-8">
                        {/* <div className="hidden lg:flex">
                          <span className="-m-2 cursor-pointer p-2 text-slate-400 hover:text-slate-500" onClick={() => show()}>
                            <span className="sr-only">Search</span>
                            <MagnifyingGlassIcon className="h-6 w-6" aria-hidden="true" />
                          </span>
                        </div> */}

                        {/* <div className="flex">
                            <Link to="/checkout" className="-m-2 p-2 text-slate-400 hover:text-slate-500">
                              <span className="sr-only">Account</span>
                              <UserIcon className="h-6 w-6" aria-hidden="true" />
                            </Link>
                          </div> */}
                      </div>

                      {/* <span className="mx-4 h-6 w-px bg-slate-200 lg:mx-6" aria-hidden="true" /> */}

                      <div className="flow-root">
                        <Link to="/cart" className="group -m-2 flex items-center gap-2 p-2">
                          {/* <ShoppingCartIcon className="h-6 w-6 flex-shrink-0 text-slate-400 group-hover:text-slate-500" aria-hidden="true" /> */}
                          {/* <span className="ml-2 text-sm font-medium text-slate-700 group-hover:text-slate-800">0</span>
                            <span className="sr-only">items in cart, view bag</span> */}
                          <img src="/assets/img/plane.svg" className="h-7 w-7 flex-shrink-0 hover:opacity-80" alt="Tuple" />{" "}
                          <span className="p-2 text-sm font-medium text-slate-900">Покупки</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      {/* Search */}
      <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery("")} appear>
        <Dialog as="div" className="relative z-10" onClose={hide}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-slate-500 bg-opacity-25 backdrop-blur-md transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-slate-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Combobox onChange={(item: any) => (window.location = item.url)}>
                  <div className="relative">
                    <MagnifyingGlassIconMini className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-slate-400" aria-hidden="true" />
                    <Combobox.Input
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-slate-800 placeholder-slate-400 focus:ring-0 sm:text-sm"
                      placeholder="Search..."
                      onChange={(event) => setQuery(event.target.value)}
                    />
                  </div>

                  {(query === "" || filteredProjects.length > 0) && (
                    <Combobox.Options static className="max-h-80 scroll-py-2 divide-y divide-slate-100 overflow-y-auto">
                      <li className="p-2">
                        {query === "" && <h2 className="mt-4 mb-2 px-3 text-xs font-semibold text-slate-500">Недавно искали</h2>}
                        <ul className="text-sm text-slate-700">
                          {(query === "" ? recent : filteredProjects).map((project) => (
                            <Combobox.Option
                              key={project.id}
                              value={project}
                              className={({ active }: any) =>
                                classNames("flex cursor-default select-none items-center rounded-md px-3 py-2", active && "bg-emerald-400 text-white")
                              }
                            >
                              {({ active }) => (
                                <>
                                  <FolderIcon className={classNames("h-6 w-6 flex-none", active ? "text-white" : "text-slate-400")} aria-hidden="true" />
                                  <span className="ml-3 flex-auto truncate">{project.name}</span>
                                  {active && <span className="ml-3 flex-none text-emerald-100">Jump to...</span>}
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </ul>
                      </li>
                      {query === "" && (
                        <li className="p-2">
                          <h2 className="sr-only">Quick actions</h2>
                          <ul className="text-sm text-slate-700">
                            {quickActions.map((action) => (
                              <Combobox.Option
                                key={action.shortcut}
                                value={action}
                                className={({ active }: any) =>
                                  classNames("flex cursor-default select-none items-center rounded-md px-3 py-2", active && "bg-emerald-400 text-white")
                                }
                              >
                                {({ active }) => (
                                  <>
                                    <action.icon className={classNames("h-6 w-6 flex-none", active ? "text-white" : "text-slate-400")} aria-hidden="true" />
                                    <span className="ml-3 flex-auto truncate">{action.name}</span>
                                    <span className={classNames("ml-3 flex-none text-xs font-semibold", active ? "text-emerald-100" : "text-slate-400")}>
                                      <kbd className="font-sans">⌘</kbd>
                                      <kbd className="font-sans">{action.shortcut}</kbd>
                                    </span>
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                          </ul>
                        </li>
                      )}
                    </Combobox.Options>
                  )}

                  {query !== "" && filteredProjects.length === 0 && (
                    <div className="py-14 px-6 text-center sm:px-14">
                      <FolderIcon className="mx-auto h-6 w-6 text-slate-400" aria-hidden="true" />
                      <p className="mt-4 text-sm text-slate-900">We couldn't find any projects with that term. Please try again.</p>
                    </div>
                  )}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
