import { useState, useEffect, useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { routes } from "./routes";
import { IRoute, ILayout, BgSplit } from "./interfaces/routes";
import { flattenRoutes, buildPaths, setupParents } from "./util";
import Header from "./routes/header";
import Preheader from "./routes/preheader";
import Footer from "./routes/footer";
import Aside from "./routes/aside";
import NoMatch from "./routes/pages/404";
import Page from "./page";
import ScrollToTop from "./components/scrollToTop";
import CdFramework from "./components/cdFramework";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Main() {
  return (
    <>
      <Switch>
        {flattenRoutes(setupParents(buildPaths(routes))).map((route: IRoute, i: number) => {
          return (
            <Route key={i} exact path={route.path}>
              <>
                <div className="relative flex min-h-screen flex-col">
                  {/* Banner */}
                  {/* <Preheader></Preheader> */}

                  {/* Navbar */}
                  <Header></Header>

                  {/* Main content */}
                  <Page route={route} />
                </div>

                <Footer />

                <ScrollToTop />
                <CdFramework />
              </>
            </Route>
          );
        })}

        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </>
  );
}
