/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { Fragment, useState } from "react";
import { Dialog, Popover, Tab, Transition, Menu } from "@headlessui/react";
import { Bars3Icon, MagnifyingGlassIcon, ShoppingCartIcon, UserIcon, XMarkIcon, CheckIcon } from "@heroicons/react/24/outline";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import { Link, useHistory } from "react-router-dom";
import Products from "../../components/products";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";

const features = [
  {
    name: "Профессионализм",
    description: "Мы знаем специфику международных поставок.",
  },
  { name: "Нам доверяют ", description: "Лидеры отраслей FMCG, федеральные сети и крупные дистрибьюторы." },
  {
    name: "Соблюдаем сроки",
    description: "Высокая скорость отгрузки со склада. Фиксируем график поставки ваших товаров.",
  },
  { name: "Оригинальная продукция", description: "Предоставляем всю импортную и таможенную документацию." },
  { name: "Логистика", description: "Доставка заказа на следующей день по Москве и Московской области с нашего склада." },
  { name: "Скидки", description: "Гибкий подход к ценообразованию." },
  { name: "Конфиденциальность", description: "Гарантируем конфиденциальность любой полученной от вас информации." },
  { name: "Товары", description: "В нашем каталоге представлены товары от мировых А-брендов." },
];

const offers = [
  { name: "Клиенты", description: "70 % рынка РФ корпоративных клиентов", href: "#" },
  { name: "География", description: "5 стран присутствия", href: "#" },
  { name: "Партнеры", description: "86 брендов", href: "#" },
];
const categories = [
  {
    name: "Электроника",
    href: "",
    imageSrc: "/assets/img/pexels-elvis-2528118.jpg",
  },
  {
    name: "Игрушки",
    href: "/kids",
    imageSrc: "/assets/img/10948_5.jpg",
  },
  {
    name: "Животным",
    href: "",
    imageSrc: "/assets/img/pexels-valeria-boltneva-1805164.jpg",
  },
  {
    name: "Для дома",
    href: "",
    imageSrc: "/assets/img/pexels-pixabay-462235.jpg",
  },
  { name: "Красота", href: "", imageSrc: "/assets/img/pexels-ekaterina-bolovtsova-4672733.jpg" },
];

const callouts = [
  {
    name: "Desk and Office",
    description: "Work from home accessories",
    imageSrc: "https://tailwindui.com/img/ecommerce-images/home-page-02-edition-01.jpg",
    imageAlt: "Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.",
    href: "#",
  },
  {
    name: "Self-Improvement",
    description: "Journals and note-taking",
    imageSrc: "https://tailwindui.com/img/ecommerce-images/home-page-02-edition-02.jpg",
    imageAlt: "Wood table with porcelain mug, leather journal, brass pen, leather key ring, and a houseplant.",
    href: "#",
  },
  {
    name: "Travel",
    description: "Daily commute essentials",
    imageSrc: "https://tailwindui.com/img/ecommerce-images/home-page-02-edition-03.jpg",
    imageAlt: "Collection of four insulated travel bottles on wooden shelf.",
    href: "#",
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Home() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const history = useHistory();

  const goToSeries = (series: string) => {
    const newActiveFilters = [{ value: "Серия", label: series }];

    try {
      window.localStorage.setItem("activeFilters", JSON.stringify(newActiveFilters));
    } catch {}

    history.push("/kids/lego#products");
  };

  return (
    <div className="bg-white">
      <main>
        {/* Hero */}
        <div className="flex flex-col border-b border-gray-200 lg:border-0">
          <nav aria-label="Offers" className="order-last lg:order-first">
            <div className="mx-auto max-w-7xl lg:px-8">
              <ul role="list" className="grid grid-cols-1 divide-y divide-gray-200 lg:grid-cols-3 lg:divide-y-0 lg:divide-x">
                {offers.map((offer) => (
                  <li key={offer.name} className="flex flex-col">
                    <a href={offer.href} className="relative flex flex-1 flex-col justify-center bg-white py-6 px-4 text-center focus:z-10">
                      <p className="text-sm text-gray-500">{offer.name}</p>
                      <p className="font-semibold text-gray-900">{offer.description}</p>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </nav>

          <div className="relative">
            <div aria-hidden="true" className="absolute hidden h-full w-1/2 bg-gray-100 lg:block" />
            <div className="relative bg-gray-100 lg:bg-transparent">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:grid lg:grid-cols-2 lg:px-8">
                <div className="mx-auto max-w-2xl py-24 lg:max-w-none lg:py-64">
                  <div className="lg:pr-16">
                    <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl xl:text-6xl">
                      Ваш надежный поставщик <br /> А-брендов
                    </h1>
                    <p className="mt-4 text-xl text-gray-600">
                      Лидер по импорту товаров. Мы отслеживаем все происходящие изменения в этой сфере, что позволяет нам найти нестандартные подходы для вашего
                      бизнеса.
                    </p>
                    <div className="mt-6">
                      <Link
                        to="/cart"
                        className="inline-block rounded-md border border-transparent bg-emerald-400 py-3 px-8 font-medium text-white hover:bg-emerald-500"
                      >
                        Заказать
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-48 w-full sm:h-64 lg:absolute lg:top-0 lg:right-0 lg:h-full lg:w-1/2">
              <img src="/assets/img/Free_Shipping_Container_Mockup_3.jpg" alt="" className="h-full w-full object-cover object-center" />
            </div>
          </div>
        </div>

        {/* Feature */}
        <div className="bg-white">
          <div className="mx-auto my-24 max-w-7xl px-6 lg:grid lg:grid-cols-3 lg:gap-x-12 lg:px-8">
            <div>
              {/* <h2 className="text-lg font-semibold leading-8 tracking-tight text-emerald-400">Фокус только на поставки</h2> */}
              <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900">Поставка товаров «под ключ»</p>
              <p className="mt-6 text-base leading-7 text-gray-600">
                Мы предлагаем нашим клиентам высокий уровень сервиса и профессиональную работу наших сотрудников на всех этапах взаимодействия.
              </p>
            </div>
            <div className="mt-20 lg:col-span-2 lg:mt-0">
              <dl className="grid grid-cols-1 gap-12 sm:grid-flow-col sm:grid-cols-2 sm:grid-rows-4">
                {features.map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt>
                      <CheckIcon className="absolute mt-1 h-6 w-6 text-emerald-400" aria-hidden="true" />
                      <p className="ml-10 text-lg font-semibold leading-8 text-gray-900">{feature.name}</p>
                    </dt>
                    <dd className="mt-2 ml-10 text-base leading-7 text-gray-600">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>

        {/* Trending products */}
        {/* <Products /> */}

        {/* Category section */}
        <div className="bg-gray-50">
          <div className="py-16 sm:py-16 xl:mx-auto xl:max-w-7xl xl:px-8">
            <div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900">Каталог</h2>
              {/* <a href="#" className="hidden text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block">
                Browse all categories
                <span aria-hidden="true"> &rarr;</span>
              </a> */}
            </div>

            <div className="mt-4 flow-root">
              <div className="-my-2">
                <div className="relative box-content h-80 overflow-x-auto py-2 xl:overflow-visible">
                  <div className="min-w-screen-xl absolute flex space-x-8 px-4 sm:px-6 lg:px-8 xl:relative xl:grid xl:grid-cols-5 xl:gap-x-8 xl:space-x-0 xl:px-0">
                    {categories.map((category) => (
                      <Link
                        key={category.name}
                        to={category.href}
                        className="relative flex h-80 w-56 flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto"
                      >
                        <span aria-hidden="true" className="absolute inset-0">
                          <img src={category.imageSrc} alt="" className="h-full w-full object-cover object-center" />
                        </span>
                        <span aria-hidden="true" className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50" />
                        <span className="relative mt-auto flex items-center justify-center text-center text-xl font-bold text-white">
                          {category.name}{" "}
                          {category.name !== "Игрушки" ? <LockClosedIcon className="ml-2 h-5 w-5 flex-shrink-0 text-white/60" aria-hidden="true" /> : ""}
                        </span>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="mt-6 px-4 sm:hidden">
              <a href="#" className="block text-sm font-semibold text-indigo-600 hover:text-indigo-500">
                Browse all categories
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </div> */}
          </div>
        </div>

        {/* Category section */}
        <div className="bg-gray-50">
          <div className="mx-auto max-w-7xl px-4 pt-16 pb-32 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-baseline sm:justify-between">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900">Производители</h2>
              <Link to="/kids" className="hidden text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block">
                Все прооизводители
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
              <div className="group aspect-w-2 aspect-h-1 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2">
                <img src="/assets/img/76951_WEB_PRI.webp" alt="" className="object-cover object-center group-hover:opacity-75" />
                <div aria-hidden="true" className="bg-gradient-to-b from-transparent to-black opacity-50" />
                <div className="flex items-end p-6">
                  <div>
                    <h3 className="font-semibold text-white">
                      <Link to="/kids/lego">
                        <span className="absolute inset-0" />
                        LEGO
                      </Link>
                    </h3>
                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                      Подробнее
                    </p>
                  </div>
                </div>
              </div>
              <div className="group aspect-w-2 aspect-h-1 overflow-hidden rounded-lg sm:aspect-none sm:relative sm:h-full">
                <img
                  src="/assets/img/1132805.jpg"
                  alt=""
                  className="object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full"
                />
                <div aria-hidden="true" className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0" />
                <div className="flex items-end p-6 sm:absolute sm:inset-0">
                  <div>
                    <h3 className="font-semibold text-white">
                      <Link to="/kids/mattel">
                        <span className="absolute inset-0" />
                        MATTEL
                      </Link>
                    </h3>
                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                      Подробнее
                    </p>
                  </div>
                </div>
              </div>
              <div className="group aspect-w-2 aspect-h-1 overflow-hidden rounded-lg sm:aspect-none sm:relative sm:h-full">
                <img
                  src="/assets/img/C8fLTfAW0AA2XAQ.jpg"
                  alt=""
                  className="object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full"
                />
                <div aria-hidden="true" className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0" />
                <div className="flex items-end p-6 sm:absolute sm:inset-0">
                  <div>
                    <h3 className="font-semibold text-white">
                      <Link to="/kids/hasbro">
                        <span className="absolute inset-0" />
                        Hasbro
                      </Link>
                    </h3>
                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                      Подробнее
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 sm:hidden">
              <Link to="/kids" className="block text-sm font-semibold text-indigo-600 hover:text-indigo-500">
                Все прооизводители
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>

        <Swiper spaceBetween={0} slidesPerView={1} onSlideChange={() => console.log("slide change")} onSwiper={(swiper) => console.log(swiper)}>
          <SwiperSlide className="cursor-grab">
            <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
              <img
                src="/assets/img/02-Hero-Tall-Avatar-GU-MainPage-Desktop.webp"
                alt=""
                className="absolute inset-0 -z-10 h-full w-full object-cover opacity-70"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1097 845"
                aria-hidden="true"
                className="hidden transform-gpu blur-3xl sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:w-[68.5625rem]"
              >
                <path
                  fill="url(#10724532-9d81-43d2-bb94-866e98dd6e42)"
                  fillOpacity=".2"
                  d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
                />
                <defs>
                  <linearGradient id="10724532-9d81-43d2-bb94-866e98dd6e42" x1="1097.04" x2="-141.165" y1=".22" y2="363.075" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#776FFF" />
                    <stop offset={1} stopColor="#FF4694" />
                  </linearGradient>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1097 845"
                aria-hidden="true"
                className="absolute left-1/2 -top-52 -z-10 w-[68.5625rem] -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0"
              >
                <path
                  fill="url(#8ddc7edb-8983-4cd7-bccb-79ad21097d70)"
                  fillOpacity=".2"
                  d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
                />
                <defs>
                  <linearGradient id="8ddc7edb-8983-4cd7-bccb-79ad21097d70" x1="1097.04" x2="-141.165" y1=".22" y2="363.075" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#776FFF" />
                    <stop offset={1} stopColor="#FF4694" />
                  </linearGradient>
                </defs>
              </svg>
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0">
                  <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Новинки</h2>
                  <p className="mt-6 text-lg leading-8 text-slate-100">
                    LEGO® Avatar: Build your bond. <br />
                    Подарите билет во вселенную «Аватара» любителям научной фантастики старше 12 лет с набором LEGO® Avatar.
                  </p>
                  <Link
                    to="/kids/lego"
                    className="mt-6 inline-flex rounded-md bg-emerald-400 py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-400"
                  >
                    Подробнее
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        {/* Sale and testimonials */}
        <div className="relative mt-16 overflow-hidden">
          {/* Decorative background image and gradient */}
          <div aria-hidden="true" className="absolute inset-0">
            <div className="absolute inset-0 mx-auto max-w-7xl overflow-hidden xl:px-8">
              <img
                src="https://tailwindui.com/img/ecommerce-images/home-page-02-sale-full-width.jpg"
                alt=""
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="absolute inset-0 bg-white bg-opacity-75" />
            <div className="absolute inset-0 bg-gradient-to-t from-white via-white" />
          </div>

          {/* Sale */}
          <section aria-labelledby="sale-heading" className="relative mx-auto flex max-w-7xl flex-col items-center px-4 pt-32 text-center sm:px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:max-w-none">
              <h2 id="sale-heading" className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
                Откроем для вас весь мир
              </h2>
              <p className="mx-auto mt-4 max-w-xl text-xl text-gray-600">Наличие в портфеле А-брендов и высокая скорость доставки.</p>
              <Link
                to="/cart"
                className="mt-6 inline-block w-full rounded-md border border-transparent bg-gray-900 py-3 px-8 font-medium text-white hover:bg-gray-800 sm:w-auto"
              >
                Заказать
              </Link>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}
