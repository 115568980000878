import { Fragment, useState, useEffect, useRef } from "react";
import clsx from "clsx";

export default function CheckImage({ className, alt, src }: any) {
  const imgRef = useRef(null);

  let [error, setError] = useState();

  return (
    <>
      <img ref={imgRef} src={src} alt={alt} className={clsx(!!error ? "hidden" : "", className)} onError={(err: any) => setError(err)} />

      <img src="/assets/img/sticky-feature-img-2.png" alt="Image not found" className={clsx(!error ? "hidden" : "", className, "opacity-40")} />
    </>
  );
}
