/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { Fragment, useState } from "react";
import { Dialog, Disclosure, Menu, Popover, Tab, Transition } from "@headlessui/react";
import { Bars3Icon, MagnifyingGlassIcon, ShoppingBagIcon, XMarkIcon, ShoppingCartIcon, UserIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Products from "../../components/products";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";

import { LifebuoyIcon, NewspaperIcon, PhoneIcon } from "@heroicons/react/20/solid";
const stats = [
  { label: "Основана", value: "1932" },
  { label: "Запуск в текущем виде", value: "1958" },
];

const callouts = [
  {
    name: "Architecture",
    href: "#",
    imageSrc: "/assets/img/8bfeda054160830e6110cd2ea81a92e4.jpg",
    imageAlt: "Architecture",
  },
  {
    name: "Batman",
    href: "#",
    imageSrc: "/assets/img/5aa1b6afb900c0f10bfc728d4dbe9233.jpg",
    imageAlt: "Batman",
  },
  {
    name: "City",
    href: "#",
    imageSrc: "/assets/img/aa3fc5c24d396c9415f9eaf785bbbbed.jpg",
    imageAlt: "City",
  },
  {
    name: "Classic",
    href: "#",
    imageSrc: "/assets/img/5aa1b6afb900c0f10bfc728d4dbe9233.jpg",
    imageAlt: "Classic",
  },
  {
    name: "Creator",
    href: "#",
    imageSrc: "/assets/img/2ff54266c22f60a9089629133d76dc73.jpg",
    imageAlt: "Creator",
  },
  {
    name: "DC",
    href: "#",
    imageSrc: "/assets/img/5aa1b6afb900c0f10bfc728d4dbe9233.jpg",
    imageAlt: "DC",
  },
  {
    name: "Disney",
    href: "#",
    imageSrc: "/assets/img/bad0b6db84e61118c4edef351f173c9b.jpg",
    imageAlt: "Disney",
  },
  {
    name: "DOTS",
    href: "#",
    imageSrc: "/assets/img/0dceea59d6e415759c9218db4c0673a6.jpg",
    imageAlt: "DOTS",
  },
  {
    name: "DUPLO",
    href: "#",
    imageSrc: "/assets/img/c47c2103cecfb4ee0d17c6ebdeb782c0.jpg",
    imageAlt: "DUPLO",
  },
  {
    name: "Friends",
    href: "#",
    imageSrc: "/assets/img/456b9b2dbf9302e4f46a4b6555819c5e.jpg",
    imageAlt: "Friends",
  },
  {
    name: "Harry Potter",
    href: "#",
    imageSrc: "/assets/img/822a26d37f9600940b34310273ad47d4.jpg",
    imageAlt: "Harry Potter",
  },
  {
    name: "Ideas",
    href: "#",
    imageSrc: "/assets/img/8769b84182d94f52d75c90c11475b28d.jpg",
    imageAlt: "Ideas",
  },
  {
    name: "Jurassic World",
    href: "#",
    imageSrc: "/assets/img/75b91c6177f4382c1f57ff50c3f58cce.jpg",
    imageAlt: "Jurassic World",
  },
  {
    name: "Art",
    href: "#",
    imageSrc: "/assets/img/b90977cf8a574e041e6f220cc63d3364.jpg",
    imageAlt: "Art",
  },
  {
    name: "Avatar",
    href: "#",
    imageSrc: "/assets/img/e0473357182dc90b9bb836bc7ea07cfa.jpg",
    imageAlt: "Avatar",
  },
  {
    name: "Education",
    href: "#",
    imageSrc: "/assets/img/5aa1b6afb900c0f10bfc728d4dbe9233.jpg",
    imageAlt: "Education",
  },
  {
    name: "Icons",
    href: "#",
    imageSrc: "/assets/img/2db7e516499406acef43ef6a9be83f16.jpg",
    imageAlt: "Icons",
  },
  {
    name: "Super Mario",
    href: "#",
    imageSrc: "/assets/img/839559060515bb60aaadd051bc3da7f0.jpg",
    imageAlt: "Super Mario",
  },
  {
    name: "Lord of the Rings New",
    href: "#",
    imageSrc: "/assets/img/5aa1b6afb900c0f10bfc728d4dbe9233.jpg",
    imageAlt: "Lord of the Rings New",
  },
  {
    name: "Marvel",
    href: "#",
    imageSrc: "/assets/img/ca68bc794e7d1ac6b9a38a3e1f5417bb.jpg",
    imageAlt: "Marvel",
  },
  {
    name: "Minecraft",
    href: "#",
    imageSrc: "/assets/img/b518ddd7b59f761dec687e8e7e306c33.jpg",
    imageAlt: "Minecraft",
  },
  {
    name: "Minifigures",
    href: "#",
    imageSrc: "/assets/img/9445310540a508d99f8639a64b8a16e2.jpg",
    imageAlt: "Minifigures",
  },
  {
    name: "Minions",
    href: "#",
    imageSrc: "/assets/img/830351d6e9c31230c694410c744ebf34.jpg",
    imageAlt: "Minions",
  },
  {
    name: "Monkie Kid",
    href: "#",
    imageSrc: "/assets/img/50f0b8702a2b275ce1396454cfcf0164.jpg",
    imageAlt: "Monkie Kid",
  },
  {
    name: "NINJAGO",
    href: "#",
    imageSrc: "/assets/img/3392e199fa9722080e5d0824081da8e5.jpg",
    imageAlt: "NINJAGO",
  },
  {
    name: "Speed Champions",
    href: "#",
    imageSrc: "/assets/img/9aa0ee46c1c842c8e675ae53fe479a47.jpg",
    imageAlt: "Speed Champions",
  },
  {
    name: "Spider Man",
    href: "#",
    imageSrc: "/assets/img/08a545013bbf15e802b2e0b72af10ee5.jpg",
    imageAlt: "Spider Man",
  },
  {
    name: "Star Wars",
    href: "#",
    imageSrc: "/assets/img/987679afb07626085ea24e7fb6d8425b.jpg",
    imageAlt: "Star Wars",
  },
  {
    name: "Technic",
    href: "#",
    imageSrc: "/assets/img/31f2b4f6083264684df5cf751b142c28.jpg",
    imageAlt: "Technic",
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Lego() {
  const [open, setOpen] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [propsActiveFilter, setPropsActiveFiter] = useState<any>();
  const [openCallouts, setOpenCallouts] = useState(false);

  const addPropsActiveFilter = (value: string) => {
    const newPropsActiveFilter = { section: "Серия", value };

    setPropsActiveFiter(newPropsActiveFilter);
  };

  return (
    <div className="bg-white">
      <div className="relative bg-white">
        <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
          <div className="px-6 pt-10 pb-24 sm:pb-32 lg:col-span-7 lg:px-0 lg:pt-48 lg:pb-56 xl:col-span-6">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <img src="/assets/img/LEGO_logo.svg.png" className=" h-[100px] w-[100px]" alt="" />

              {/* <div className="hidden sm:mt-32 sm:flex lg:mt-16">
                <div className="relative rounded-full py-1 px-3 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                  Новая коллекция LEGO® Avatar: Build your bond.{" "}
                  <a href="#products" className="whitespace-nowrap font-semibold text-indigo-600">
                    <span className="absolute inset-0" aria-hidden="true" />
                    Каталог <span aria-hidden="true">&rarr;</span>
                  </a>
                </div>
              </div> */}
              <h1 className="mt-24 text-4xl font-bold tracking-tight text-gray-900 sm:mt-10 sm:text-6xl">LEGO</h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Компания Lego знает, что игра жизненно важна для развития каждого ребенка. Когда дети играют, они учатся решать проблемы, проявлять творческие
                способности и быть выносливыми. Это помогает им преуспевать в сложном и трудном мире.
              </p>
            </div>
          </div>
          <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
            <img
              className="lg:aspect-auto aspect-[3/2] w-full bg-gray-50 object-cover object-left lg:absolute lg:inset-0 lg:h-full"
              src="/assets/img/06-Hero-XL-Avatar-GU-MainPage-Desktop.jpg"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="bg-white pt-24 sm:pt-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-y-16 gap-x-8 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-4">
              <div className="relative overflow-hidden rounded-3xl bg-yellow-500 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                <img className="absolute inset-0 h-full w-full object-cover brightness-125 saturate-0" src="/assets/img/21184_2.jpg" alt="" />
                <div className="absolute inset-0 bg-yellow-900 mix-blend-multiply" />
                <svg
                  viewBox="0 0 1097 845"
                  aria-hidden="true"
                  className="absolute top-1/2 left-1/2 -ml-16 w-[68.5625rem] -translate-x-1/2 -translate-y-1/2 transform-gpu blur-3xl"
                >
                  <path
                    fill="url(#e7ccf164-908d-46dd-9194-2f8a16ad5a93)"
                    fillOpacity=".4"
                    d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
                  />
                  <defs>
                    <linearGradient id="e7ccf164-908d-46dd-9194-2f8a16ad5a93" x1="1097.04" x2="-141.165" y1=".22" y2="363.075" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#776FFF" />
                      <stop offset={1} stopColor="#FF4694" />
                    </linearGradient>
                  </defs>
                </svg>
                <figure className="relative isolate">
                  <svg viewBox="0 0 162 128" fill="none" aria-hidden="true" className="absolute -top-4 -left-2 -z-10 h-32 stroke-white/20">
                    <path
                      id="0ef284b8-28c2-426e-9442-8655d393522e"
                      d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z"
                    />
                    <use href="#0ef284b8-28c2-426e-9442-8655d393522e" x={86} />
                  </svg>
                  <blockquote className="mt-6 text-xl font-semibold leading-8 text-white">
                    <p>“Only the best is good enough, because your children deserve the best.”</p>
                  </blockquote>
                  <figcaption className="mt-6 text-sm leading-6 text-gray-300">
                    <strong className="font-semibold text-white">Ole Kirk Christiansen,</strong> Основатель
                  </figcaption>
                </figure>
              </div>
            </div>
            <div>
              <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                <p className="text-base font-semibold leading-7 text-indigo-600">О компании</p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">История LEGO Group</h1>
                <div className="max-w-xl">
                  <p className="mt-6">
                    Название LEGO - это аббревиатура двух датских слов "leg godt", что означает "хорошо играть". Группа LEGO была основана в 1932 году Оле
                    Кирком Кристиансеном. Компания переходила от отца к сыну и сейчас принадлежит Кьельду Кирку Кристиансену, внуку основателя.
                  </p>
                </div>
              </div>
              <dl className="mt-10 grid grid-cols-2 gap-8 border-t border-gray-900/10 pt-10 ">
                {stats.map((stat, statIdx) => (
                  <div key={statIdx}>
                    <dt className="text-sm font-semibold leading-6 text-gray-600">{stat.label}</dt>
                    <dd className="mt-2 text-3xl font-bold leading-10 tracking-tight text-gray-900">{stat.value}</dd>
                  </div>
                ))}
              </dl>
              <div className="mt-10 flex">
                <a target="_blank" href="https://www.lego.com/en-us/history/" className="text-base font-semibold leading-7 text-indigo-600">
                  Посетите сайт истории LEGO <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <main>
        {/* Category section */}
        <div className="mt-10">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-2xl py-16 lg:max-w-none">
              <div className="sm:flex sm:items-baseline sm:justify-between">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900">Серии LEGO</h2>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-y-4 sm:grid-cols-6 sm:gap-x-3 sm:gap-y-4">
                {callouts.slice(0, openCallouts ? callouts.length : 6).map((series) => (
                  <a
                    key={series.name}
                    href="#products"
                    onClick={() => addPropsActiveFilter(series.name)}
                    className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
                  >
                    <div className="bg-gray-200 group-hover:opacity-75">
                      <img src={series.imageSrc} alt={series.imageAlt} className="h-full w-full object-cover object-center sm:h-full sm:w-full" />
                    </div>

                    <div className="flex flex-1 flex-col space-y-2 p-4">
                      <h3 className="text-sm font-medium text-gray-900">
                        <span aria-hidden="true" className="absolute inset-0" />
                        {series.name}
                      </h3>
                      <p className="text-sm text-gray-500">LEGO</p>
                    </div>
                  </a>
                ))}
              </div>

              {callouts.length > 6 && (
                <div className="mt-8 flex justify-center">
                  {openCallouts ? (
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-400 focus:ring-offset-2"
                      onClick={() => setOpenCallouts(false)}
                    >
                      Скрыть
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-400 focus:ring-offset-2"
                      onClick={() => setOpenCallouts(true)}
                    >
                      Показать все
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Featured section */}
        {/* <section aria-labelledby="cause-heading">
          <div className="relative my-10 bg-gray-800 py-32 px-6 sm:py-40 sm:px-12 lg:px-1">
            <div className="absolute inset-0 overflow-hidden">
              <img
                src="https://tailwindui.com/img/ecommerce-images/home-page-03-feature-section-full-width.jpg"
                alt=""
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div aria-hidden="true" className="absolute inset-0 bg-gray-900 bg-opacity-50" />
            <div className="relative mx-auto flex max-w-3xl flex-col items-center text-center">
              <h2 id="cause-heading" className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Long-term thinking
              </h2>
              <p className="mt-3 text-xl text-white">
                We're committed to responsible, sustainable, and ethical manufacturing. Our small-scale approach allows us to focus on quality and reduce our
                impact. We're doing our best to delay the inevitable heat-death of the universe.
              </p>
              <a
                href="#"
                className="mt-8 block w-full rounded-md border border-transparent bg-white py-3 px-8 text-base font-medium text-gray-900 hover:bg-gray-100 sm:w-auto"
              >
                Read our story
              </a>
            </div>
          </div>
        </section> */}

        {/* Favorites section */}
        <Products propsActiveFilter={propsActiveFilter} />

        {/* CTA section */}
        <section aria-labelledby="sale-heading">
          <div className="overflow-hidden pt-32 sm:pt-14">
            <div className="bg-gray-100">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="relative pt-48 pb-16 sm:pb-24">
                  <div>
                    <h2 id="sale-heading" className="text-5xl tracking-tight text-gray-900">
                      Индивидуальный подход <br /> к каждому клиенту
                      <br />
                    </h2>
                    <p className="mt-4 block text-2xl font-normal">Гибкие условия сотрудничества.</p>
                    <div className="mt-6 text-base">
                      <Link
                        to="/cart"
                        className="inline-block rounded-md border border-transparent bg-emerald-400 py-3 px-8 text-center font-medium text-white hover:bg-emerald-600"
                      >
                        Сделать заказ
                      </Link>
                    </div>
                  </div>

                  <div className="absolute -top-32 left-1/2 -translate-x-1/2 transform sm:top-6 sm:translate-x-0">
                    <div className="ml-24 flex min-w-max space-x-6 sm:ml-3 lg:space-x-8">
                      <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                        <div className="flex-shrink-0">
                          <img className="h-64 w-64 rounded-lg object-cover md:h-96 md:w-72" src="/assets/img/21184_2.jpg" alt="" />
                        </div>

                        <div className="mt-6 flex-shrink-0 sm:mt-0">
                          <img className="h-64 w-64 rounded-lg object-cover md:h-96 md:w-72" src="/assets/img/21170_2.jpg" alt="" />
                        </div>
                      </div>
                      <div className="flex space-x-6 sm:-mt-20 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                        <div className="flex-shrink-0">
                          <img className="h-64 w-64 rounded-lg object-cover md:h-96 md:w-72" src="/assets/img/31114_10.jpg" alt="" />
                        </div>

                        <div className="mt-6 flex-shrink-0 sm:mt-0">
                          <img className="h-64 w-64 rounded-lg object-cover md:h-96 md:w-72" src="/assets/img/31127_2.jpg" alt="" />
                        </div>
                      </div>
                      <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                        <div className="flex-shrink-0">
                          <img className="h-64 w-64 rounded-lg object-cover md:h-96 md:w-72" src="/assets/img/21331_7.jpg" alt="" />
                        </div>

                        <div className="mt-6 flex-shrink-0 sm:mt-0">
                          <img className="h-64 w-64 rounded-lg object-cover md:h-96 md:w-72" src="/assets/img/31058_2.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
