import { Fragment, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dialog, Disclosure, Menu, Popover, Tab, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  XMarkIcon,
  ShoppingCartIcon,
  UserIcon,
  PlusIcon,
  CheckCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import productsJson from "../productsJson.json";
import CheckImage from "../components/checkImage";
import dayjs from "dayjs";
import "dayjs/locale/ru";

dayjs.locale("ru");

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Products({ propsActiveFilter, title, subtitle }: any) {
  const [open, setOpen] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const { pathname } = useLocation();
  const STEP = 20;

  const arrUniqueByCategory = Array.from(new Set(productsJson.map((item: any) => item["Категория"])));
  const arrUniqueByBrand = Array.from(new Set(productsJson.map((item: any) => item["Бренд"])));
  const arrUniqueBySeries = Array.from(new Set(productsJson.map((item: any) => item["Серия"])));

  const [products, setProducts] = useState(productsJson);
  const [filters, setFilters] = useState<any[]>([]);
  const [sortOptions, setSortOptions] = useState<any[]>([]);
  const [activeFilters, setActiveFilters] = useState<any[]>([]);
  const [limit, setLimit] = useState(STEP);
  const [showAdd, setShowAdd] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [cart, setCart] = useState<any[]>([]);

  useEffect(() => {
    const newActiveFilters = JSON.parse(window.localStorage.activeFilters ?? "[]");

    setActiveFilters(newActiveFilters);
    setFilters([
      {
        id: "category",
        name: "Категория",
        options: arrUniqueByCategory.map((i) => ({
          value: i,
          label: i,
          checked:
            newActiveFilters
              .filter((i: any) => i.value === "Категория")
              ?.map((i: any) => i.label)
              .indexOf(i) !== -1,
        })),
      },
      {
        id: "brands",
        name: "Бренд",
        options: arrUniqueByBrand.map((i) => ({
          value: i,
          label: i,
          checked:
            newActiveFilters
              .filter((i: any) => i.value === "Бренд")
              ?.map((i: any) => i.label)
              .indexOf(i) !== -1,
        })),
      },
      {
        id: "series",
        name: "Серия",
        options: arrUniqueBySeries.map((i) => ({
          value: i,
          label: i,
          checked:
            newActiveFilters
              .filter((i: any) => i.value === "Серия")
              ?.map((i: any) => i.label)
              .indexOf(i) !== -1,
        })),
      },
    ]);
  }, []);

  useEffect(() => {
    let { section, value } = propsActiveFilter ?? {};

    if (
      section &&
      value &&
      activeFilters
        .filter((i) => i.value === section)
        .map((i) => i.label)
        .indexOf(value) === -1
    ) {
      onActiveFiltersChange(section, value, true);
    }
  }, [propsActiveFilter]);

  useEffect(() => {
    let newSortOptions = JSON.parse(
      window.localStorage.sortOptions ??
        JSON.stringify([
          { name: "Рекомендации", value: "Рекомендации", current: true },
          { name: "Новинки", value: "Новинки", current: false },
          { name: "По названию", value: "По названию", current: false },
          { name: "По серии", value: "Серия", current: false },
          { name: "По артикулу", value: "Артикул", current: false },
        ])
    );

    if (newSortOptions.length < 5) {
      newSortOptions = [
        { name: "Рекомендации", value: "Рекомендации", current: true },
        { name: "Новинки", value: "Новинки", current: false },
        { name: "По названию", value: "По названию", current: false },
        { name: "По серии", value: "Серия", current: false },
        { name: "По артикулу", value: "Артикул", current: false },
      ];

      window.localStorage.setItem("sortOptions", JSON.stringify(newSortOptions));
    }

    setSortOptions(newSortOptions);
  }, []);

  useEffect(() => {
    if (sortOptions.length > 0) {
      const activeSort = sortOptions.filter((o) => o.current === true)[0];
      const newProducts = [...products];

      switch (activeSort.name) {
        case "По сериии":
          newProducts.sort((a: any, b: any) => a["Наименование"] - b["Наименование"]);
          setProducts(newProducts);

          break;

        case "Новинки":
          newProducts.sort((a: any, b: any) => dayjs(b["Дата запуска"], "DD-MM-YY")?.millisecond() - dayjs(a["Дата запуска"], "DD-MM-YY")?.millisecond());
          setProducts(newProducts);

          break;

        case "По названию":
          newProducts.sort((a: any, b: any) => a["Серия"] - b["Серия"]);
          setProducts(newProducts);

          break;

        case "По артикулу":
          newProducts.sort((a: any, b: any) => a["Артикул"] - b["Артикул"]);
          setProducts(newProducts);

          break;

        default:
          setProducts(productsJson);
      }
    }
  }, [sortOptions]);

  useEffect(() => {
    let newLimit = window.localStorage.limit ?? STEP;

    setLimit(Number(newLimit));
  }, []);

  useEffect(() => {
    setCart(JSON.parse(window.localStorage.cart ?? "[]"));
  }, []);

  const onActiveFiltersChange = (section: any, value: string, checked: boolean) => {
    const newFilters = [...filters];
    const opts = newFilters.filter((o) => o.name === section)[0]?.options;
    const filterIndex = newFilters.map((i: any) => i.name).indexOf(section);
    const optionIndex = opts.map((i: any) => i.value).indexOf(value);
    let newActiveFilters: any[] = [];

    if (filterIndex !== -1 && optionIndex !== -1) {
      newFilters[filterIndex].options[optionIndex].checked = checked;
      setFilters(newFilters);

      if (checked) {
        newActiveFilters = [...activeFilters, { value: section, label: value }];

        setActiveFilters(newActiveFilters);
      } else {
        newActiveFilters = activeFilters.filter((o) => o.label !== value);

        setActiveFilters(newActiveFilters);
      }

      try {
        window.localStorage.setItem("activeFilters", JSON.stringify(newActiveFilters));
      } catch {}

      setLimit(STEP);
      window.localStorage.setItem("limit", String(STEP));
    } else {
      newActiveFilters = activeFilters.filter((o) => o.label !== value);
      setActiveFilters(newActiveFilters);

      try {
        window.localStorage.setItem("activeFilters", JSON.stringify(newActiveFilters));
      } catch {}

      setLimit(STEP);
      window.localStorage.setItem("limit", String(STEP));
    }
  };

  const filterByType = (p: any, type: string) => {
    let res;
    let currentFilters = activeFilters.filter((o) => o.value === type);

    if (!currentFilters.length) {
      return true;
    }

    for (let i = 0; i < currentFilters.length; i++) {
      if (p[currentFilters[i].value] === currentFilters[i].label) {
        res = true;
        break;
      }
    }

    return res;
  };

  const onSortChange = (name: string) => {
    const newSortOptions = [...sortOptions];

    newSortOptions.forEach((o) => {
      if (o.name === name) {
        o.current = true;
      } else {
        o.current = false;
      }
    });

    setSortOptions(newSortOptions);
    try {
      window.localStorage.setItem("sortOptions", JSON.stringify(newSortOptions));
    } catch {}

    setLimit(STEP);
    window.localStorage.setItem("limit", String(STEP));
  };

  const onLimitChange = () => {
    const newLimit = limit + STEP;

    setLimit(newLimit);
    try {
      window.localStorage.setItem("limit", String(newLimit));
    } catch {}
  };

  const addToCart = (e: any, a: any) => {
    e.preventDefault();

    const newCart = [...cart, a];

    setShowAdd(true);
    setCart(newCart);

    try {
      window.localStorage.setItem("cart", JSON.stringify(newCart));
    } catch {}

    setTimeout(() => {
      setShowAdd(false);
    }, 2000);
  };

  const removeFromCart = (e: any, a: any) => {
    e.preventDefault();

    const newCart = cart.filter((item) => item !== a);

    setShowRemove(true);
    setCart(newCart);

    try {
      window.localStorage.setItem("cart", JSON.stringify(newCart));
    } catch {}

    setTimeout(() => {
      setShowRemove(false);
    }, 2000);
  };

  return (
    <>
      {/* Favorites section */}
      <main id="products">
        <div className="bg-white">
          <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">{title ? title : "Товары"}</h1>

            <p className="mt-4 max-w-xl text-sm text-gray-700">{subtitle ? subtitle : "Мы надежный партнер — с нами вы уверены в завтрашнем дне."}</p>
          </div>
        </div>

        {/* Filters */}
        <section aria-labelledby="filter-heading">
          <h2 id="filter-heading" className="sr-only">
            Filters
          </h2>

          <div className="border-b border-gray-200 bg-white pb-4">
            <div className="mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    Сортировка
                    <ChevronDownIcon className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {sortOptions.map((option) => (
                        <Menu.Item key={option.name}>
                          {({ active }) => (
                            <button
                              className={classNames(
                                option.current ? "font-medium text-gray-900" : "text-gray-500",
                                active ? "bg-gray-100" : "",
                                "block w-full px-4 py-2 text-left text-sm"
                              )}
                              onClick={() => onSortChange(option.name)}
                            >
                              {option.name}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>

              <button
                type="button"
                className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                Filters
              </button>

              <div className="hidden sm:block">
                <div className="flow-root">
                  <Popover.Group className="-mx-4 flex items-center divide-x divide-gray-200">
                    {filters.map((section, sectionIdx) => (
                      <Popover key={section.name} className="relative inline-block px-4 text-left">
                        <Popover.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 focus:outline-none">
                          <span>{section.name}</span>
                          {activeFilters.filter((i) => i.value === section.name).length > 0 && (
                            <span className="ml-1.5 rounded bg-gray-200 py-0.5 px-1.5 text-xs font-semibold tabular-nums text-gray-700">
                              {activeFilters.filter((i) => i.value === section.name)?.length}
                            </span>
                          )}
                          <ChevronDownIcon className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                        </Popover.Button>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Popover.Panel className="absolute right-0 z-10 mt-2 max-h-96 origin-top-right overflow-y-auto rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <form className="space-y-4">
                              {section.options.map((option: any, optionIdx: number) => (
                                <div key={`filter-${section.value}-${optionIdx}`} className="flex items-center">
                                  <input
                                    id={`filter-${section.value}-${optionIdx}`}
                                    name={`${section.value}[]`}
                                    value={option.value}
                                    type="checkbox"
                                    checked={option.checked}
                                    className="h-4 w-4 rounded border-gray-300 text-emerald-500 focus:ring-emerald-400"
                                    onChange={(e) => onActiveFiltersChange(section.name, option.value, e.target.checked)}
                                  />
                                  <label
                                    htmlFor={`filter-${section.value}-${optionIdx}`}
                                    className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </form>
                          </Popover.Panel>
                        </Transition>
                      </Popover>
                    ))}
                  </Popover.Group>
                </div>
              </div>
            </div>
          </div>

          {/* Active filters */}
          {activeFilters.length > 0 && (
            <div className="bg-gray-100">
              <div className="mx-auto max-w-7xl py-3 px-4 sm:flex sm:items-center sm:px-6 lg:px-8">
                <h3 className="text-sm font-medium text-gray-500">Фильтры</h3>

                <div aria-hidden="true" className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block" />

                <div className="mt-2 sm:mt-0 sm:ml-4">
                  <div className="-m-1 flex flex-wrap items-center">
                    {activeFilters.map((activeFilter, i) => (
                      <span
                        key={`${activeFilter.value}-${activeFilter.label}-${i}`}
                        className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
                      >
                        <span>{activeFilter.label}</span>
                        <button
                          type="button"
                          className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                          onClick={() => onActiveFiltersChange(activeFilter.value, activeFilter.label, false)}
                        >
                          <span className="sr-only">Remove filter for {activeFilter.label}</span>
                          <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                            <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                          </svg>
                        </button>
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>

        {/* Product grid */}
        <section aria-labelledby="products-heading" className="mx-auto max-w-2xl px-4 pt-12 pb-16 sm:px-6 sm:pt-16 sm:pb-24 lg:max-w-7xl lg:px-8">
          <h2 id="products-heading" className="sr-only">
            Товары
          </h2>

          <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
            {products
              .filter((p) => filterByType(p, "Категория"))
              .filter((p) => filterByType(p, "Бренд"))
              .filter((p) => filterByType(p, "Серия"))
              .slice(0, limit)
              .map((product) => (
                <Link to={`/product/${product["Артикул"]}`} key={product["Артикул"]} href={product["Артикул"]} className="group">
                  <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg xl:aspect-w-7 xl:aspect-h-8">
                    {/* <img
                      src={`/assets/i/${product["Артикул"]}.jpg`}
                      alt={product["Наименование"]}
                      className="h-full w-full object-contain object-center group-hover:opacity-75"
                      onError={(err) => console.log(err)}
                    /> */}
                    <CheckImage
                      src={`/assets/i/${product["Артикул"]}.jpg`}
                      alt={product["Наименование"]}
                      className="h-full w-full object-cover object-center group-hover:opacity-75"
                    />
                  </div>
                  <div className="flex items-start justify-between gap-1">
                    <div>
                      <h3 className="mt-4 text-sm text-gray-700">{product["Наименование"]}</h3>
                      <p className="mt-1 text-lg font-medium text-gray-900">{product["Серия"]}</p>
                    </div>

                    {cart.includes(product["Артикул"]) ? (
                      <button
                        type="button"
                        className="mt-2 flex items-center rounded-full border border-transparent bg-slate-400 p-3 text-white shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2"
                        onClick={(e: any) => removeFromCart(e, product["Артикул"])}
                      >
                        <TrashIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="mt-2 flex items-center rounded-full border border-transparent bg-emerald-400 p-3 text-white shadow-sm hover:bg-emerald-500 focus:outline-none focus:ring-2 focus:ring-emerald-400 focus:ring-offset-2"
                        onClick={(e: any) => addToCart(e, product["Артикул"])}
                      >
                        <PlusIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    )}
                  </div>
                </Link>
              ))}
          </div>

          {products
            .filter((p) => filterByType(p, "Категория"))
            .filter((p) => filterByType(p, "Бренд"))
            .filter((p) => filterByType(p, "Серия")).length > limit && (
            <div className="mt-20 flex justify-center">
              <button
                type="button"
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-400 focus:ring-offset-2"
                onClick={onLimitChange}
              >
                Показать больше
              </button>
            </div>
          )}
        </section>
      </main>

      {/* Global notification live region, render this permanently at the end of the document */}
      <div aria-live="assertive" className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6">
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={showAdd}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">Готово!</p>
                    <p className="mt-1 text-sm text-gray-500">Товар добавлен в список покупок.</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                      onClick={() => {
                        setShowAdd(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>

      {/* Global notification live region, render this permanently at the end of the document */}
      <div aria-live="assertive" className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6">
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={showRemove}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">Готово!</p>
                    <p className="mt-1 text-sm text-gray-500">Товар удален из списка покупок.</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                      onClick={() => {
                        setShowRemove(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
