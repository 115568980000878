/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { Fragment, useState } from "react";
import { Dialog, Disclosure, Menu, Popover, Tab, Transition } from "@headlessui/react";
import { Bars3Icon, MagnifyingGlassIcon, ShoppingBagIcon, XMarkIcon, ShoppingCartIcon, UserIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { ChevronDownIcon, CheckBadgeIcon, PaintBrushIcon } from "@heroicons/react/20/solid";
import Products from "../../components/products";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";

import { LifebuoyIcon, NewspaperIcon, PhoneIcon } from "@heroicons/react/20/solid";

import { ArrowPathIcon, CloudArrowUpIcon, Cog6ToothIcon, FingerPrintIcon, LockClosedIcon, ServerIcon } from "@heroicons/react/20/solid";

const features = [
  {
    name: "О компании.",
    description:
      "Mattel - ведущая мировая компания по производству игрушек и владелец одного из самых сильных портфелей детских и семейных развлекательных франшиз в мире. Их миссия заключается в создании инновационных продуктов и впечатлений, которые вдохновляют, развлекают и развивают детей через игру. Mattel, Inc. – американская транснациональная компания по производству игрушек, основанная в 1945 со штаб-квартирой в Эль-Сегундо, Калифорния (El Segundo, California). Была основана в 1945 году Эллиотом и Рут Хэндлер.",
    icon: CheckBadgeIcon,
  },
];

const cards = [
  {
    name: "Tip 1",
    description: "Consectetur vel non. Rerum ut consequatur nobis unde. Enim est quo corrupti consequatur.",
    icon: PhoneIcon,
  },
  {
    name: "Tip 2",
    description: "Quod possimus sit modi rerum exercitationem quaerat atque tenetur ullam.",
    icon: LifebuoyIcon,
  },
  {
    name: "Tip 3",
    description: "Ratione et porro eligendi est sed ratione rerum itaque. Placeat accusantium impedit eum odit.",
    icon: NewspaperIcon,
  },
  {
    name: "Tip 4",
    description: "Quod possimus sit modi rerum exercitationem quaerat atque tenetur ullam.",
    icon: NewspaperIcon,
  },
  {
    name: "Tip 5",
    description: "Consectetur vel non. Rerum ut consequatur nobis unde. Enim est quo corrupti consequatur.",
    icon: NewspaperIcon,
  },
];

const callouts = [
  {
    name: "Barbie",
    href: "#",
    imageSrc: "/assets/img/Shopper_All-Brands_Barbie.jpg",
    imageAlt: "Barbie",
  },
  {
    name: "Disney",
    href: "#",
    imageSrc: "/assets/img/Mattel_Featured-Brand-Bubbles_DisneyPrincess_1022x1022.jpg",
    imageAlt: "Disney",
  },
  {
    name: "Enchantimals",
    href: "#",
    imageSrc: "/assets/img/Shopper_All-Brands_MattelGames.jpg",
    imageAlt: "Enchantimals",
  },
  {
    name: "Fisher-Price",
    href: "#",
    imageSrc: "/assets/img/Shopper_All-Brands_FisherPrice.jpg",
    imageAlt: "Fisher-Price",
  },
  {
    name: "Hot Wheels",
    href: "#",
    imageSrc: "/assets/img/Shopper_All-Brands_HotWheels.jpg",
    imageAlt: "Hot Wheels",
  },
  {
    name: "Imaginext",
    href: "#",
    imageSrc: "/assets/img/Shopper_All-Brands_MattelGames.jpg",
    imageAlt: "Imaginext",
  },
  {
    name: "Jurassic world",
    href: "#",
    imageSrc: "/assets/img/Mattel_Featured-Brand-Bubbles_JW_1022x1022.jpg",
    imageAlt: "Jurassic world",
  },
  {
    name: "Little People",
    href: "#",
    imageSrc: "/assets/img/Shopper_All-Brands_LittlePeople.jpg",
    imageAlt: "Little People",
  },
  {
    name: "Matchbox",
    href: "#",
    imageSrc: "/assets/img/Shopper_All-Brands_MattelGames.jpg",
    imageAlt: "Matchbox",
  },
  {
    name: "MEGAbloks",
    href: "#",
    imageSrc: "/assets/img/Shopper_HP_Top-Brands_Mega_white.jpg",
    imageAlt: "MEGAbloks",
  },
  {
    name: "Minecraft",
    href: "#",
    imageSrc: "/assets/img/Shopper_All-Brands_MattelGames.jpg",
    imageAlt: "Minecraft",
  },
  {
    name: "Star Wars",
    href: "#",
    imageSrc: "/assets/img/Shopper_All-Brands_MattelGames.jpg",
    imageAlt: "Star Wars",
  },
  {
    name: "Thomas and friends",
    href: "#",
    imageSrc: "/assets/img/Shopper_All-Brands_Thomas.jpg",
    imageAlt: "Thomas and friends",
  },
  {
    name: "UNO and games",
    href: "#",
    imageSrc: "/assets/img/Shopper_All-Brands_UNO.jpg",
    imageAlt: "UNO and games",
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Mattel() {
  const [open, setOpen] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [propsActiveFilter, setPropsActiveFiter] = useState<any>();
  const [openCallouts, setOpenCallouts] = useState(false);

  const addPropsActiveFilter = (value: string) => {
    const newPropsActiveFilter = { section: "Серия", value };

    setPropsActiveFiter(newPropsActiveFilter);
  };

  return (
    <div className="bg-white">
      <div className="bg-white pt-24 sm:pt-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <img src="/assets/img/Mattel-brand.png" className="mx-auto mb-6 h-[100px] w-[100px]" alt="" />
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Mattel</p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Компания Mattel относится к игре так, как будто от нее зависит будущее - потому что так оно и есть. Игра - это язык, и производитель говорим с
              потребителями достоверно, представляя мир таким, каким они его видят и представляют.
            </p>
          </div>
        </div>
        <div className="relative overflow-hidden pt-16">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <img
              src="/assets/img/1132805.jpg"
              alt="App screenshot"
              className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
              width={2432}
              height={1442}
            />
            <div className="relative" aria-hidden="true">
              <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
            </div>
          </div>
        </div>
        <div className="mx-auto mt-16 max-w-6xl px-6 sm:mt-20 md:mt-24 lg:px-8">
          <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:gap-x-8 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="inline font-semibold text-gray-900">
                  <feature.icon className="absolute top-1 left-1 h-5 w-5 text-red-600" aria-hidden="true" />
                  {feature.name}
                </dt>{" "}
                <dd className="inline">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>

      <main>
        {/* Category section */}
        <div className="mt-10">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-2xl py-16 lg:max-w-none">
              <div className="sm:flex sm:items-baseline sm:justify-between">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900">Серии Mattel</h2>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-y-4 sm:grid-cols-6 sm:gap-x-3 sm:gap-y-4">
                {callouts.slice(0, openCallouts ? callouts.length : 6).map((series) => (
                  <a
                    key={series.name}
                    href="#products"
                    onClick={() => addPropsActiveFilter(series.name)}
                    className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
                  >
                    <div className="bg-gray-200 group-hover:opacity-75">
                      <img src={series.imageSrc} alt={series.imageAlt} className="h-full w-full object-cover object-center sm:h-full sm:w-full" />
                    </div>

                    <div className="flex flex-1 flex-col space-y-2 p-4">
                      <h3 className="text-sm font-medium text-gray-900">
                        <span aria-hidden="true" className="absolute inset-0" />
                        {series.name}
                      </h3>
                      <p className="text-sm text-gray-500">LEGO</p>
                    </div>
                  </a>
                ))}
              </div>

              {callouts.length > 6 && (
                <div className="mt-8 flex justify-center">
                  {openCallouts ? (
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-400 focus:ring-offset-2"
                      onClick={() => setOpenCallouts(false)}
                    >
                      Скрыть
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-400 focus:ring-offset-2"
                      onClick={() => setOpenCallouts(true)}
                    >
                      Показать все
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Favorites section */}
        <Products propsActiveFilter={propsActiveFilter} />
      </main>
    </div>
  );
}
