/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { Fragment, useState } from "react";
import { Dialog, Disclosure, Menu, Popover, Tab, Transition } from "@headlessui/react";
import { Bars3Icon, MagnifyingGlassIcon, ShoppingBagIcon, XMarkIcon, ShoppingCartIcon, UserIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Products from "../../components/products";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function News() {
  const [open, setOpen] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [propsActiveFilter, setPropsActiveFiter] = useState<any>();
  const [openCallouts, setOpenCallouts] = useState(false);

  const addPropsActiveFilter = (value: string) => {
    const newPropsActiveFilter = { section: "Серия", value };

    setPropsActiveFiter(newPropsActiveFilter);
  };

  return (
    <div className="bg-white">
      <main>
        {/* Category section */}

        {/* Featured section */}
        {/* <section aria-labelledby="cause-heading">
          <div className="relative my-10 bg-gray-800 py-32 px-6 sm:py-40 sm:px-12 lg:px-1">
            <div className="absolute inset-0 overflow-hidden">
              <img
                src="https://tailwindui.com/img/ecommerce-images/home-page-03-feature-section-full-width.jpg"
                alt=""
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div aria-hidden="true" className="absolute inset-0 bg-gray-900 bg-opacity-50" />
            <div className="relative mx-auto flex max-w-3xl flex-col items-center text-center">
              <h2 id="cause-heading" className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Long-term thinking
              </h2>
              <p className="mt-3 text-xl text-white">
                We're committed to responsible, sustainable, and ethical manufacturing. Our small-scale approach allows us to focus on quality and reduce our
                impact. We're doing our best to delay the inevitable heat-death of the universe.
              </p>
              <a
                href="#"
                className="mt-8 block w-full rounded-md border border-transparent bg-white py-3 px-8 text-base font-medium text-gray-900 hover:bg-gray-100 sm:w-auto"
              >
                Read our story
              </a>
            </div>
          </div>
        </section> */}

        {/* Favorites section */}
        <Products propsActiveFilter={propsActiveFilter} title="Новинки" subtitle="Эти товары скоро появятся на нашем складе." />

        {/* CTA section */}
        <section aria-labelledby="sale-heading">
          <div className="overflow-hidden pt-32 sm:pt-14">
            <div className="bg-gray-100">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="relative pt-48 pb-16 sm:pb-24">
                  <div>
                    <h2 id="sale-heading" className="text-5xl tracking-tight text-gray-900">
                      Индивидуальный подход <br /> к каждому клиенту
                      <br />
                    </h2>
                    <p className="mt-4 block text-2xl font-normal">Гибкие условия сотрудничества.</p>
                    <div className="mt-6 text-base">
                      <Link
                        to="/cart"
                        className="inline-block rounded-md border border-transparent bg-emerald-400 py-3 px-8 text-center font-medium text-white hover:bg-emerald-600"
                      >
                        Сделать заказ
                      </Link>
                    </div>
                  </div>

                  <div className="absolute -top-32 left-1/2 -translate-x-1/2 transform sm:top-6 sm:translate-x-0">
                    <div className="ml-24 flex min-w-max space-x-6 sm:ml-3 lg:space-x-8">
                      <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                        <div className="flex-shrink-0">
                          <img className="h-64 w-64 rounded-lg object-cover md:h-96 md:w-72" src="/assets/img/21184_2.jpg" alt="" />
                        </div>

                        <div className="mt-6 flex-shrink-0 sm:mt-0">
                          <img className="h-64 w-64 rounded-lg object-cover md:h-96 md:w-72" src="/assets/img/21170_2.jpg" alt="" />
                        </div>
                      </div>
                      <div className="flex space-x-6 sm:-mt-20 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                        <div className="flex-shrink-0">
                          <img className="h-64 w-64 rounded-lg object-cover md:h-96 md:w-72" src="/assets/img/31114_10.jpg" alt="" />
                        </div>

                        <div className="mt-6 flex-shrink-0 sm:mt-0">
                          <img className="h-64 w-64 rounded-lg object-cover md:h-96 md:w-72" src="/assets/img/31127_2.jpg" alt="" />
                        </div>
                      </div>
                      <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                        <div className="flex-shrink-0">
                          <img className="h-64 w-64 rounded-lg object-cover md:h-96 md:w-72" src="/assets/img/21331_7.jpg" alt="" />
                        </div>

                        <div className="mt-6 flex-shrink-0 sm:mt-0">
                          <img className="h-64 w-64 rounded-lg object-cover md:h-96 md:w-72" src="/assets/img/31058_2.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
