import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon, ChatBubbleBottomCenterTextIcon, ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

export default function Contact() {
  const [cart, setCart] = useState<any[]>([]);
  const [tempCart, setTempCart] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [company, setCompany] = useState<string>();
  const [tel, setTel] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [terms, setTerms] = useState<boolean>();
  const [showAdd, setShowAdd] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showRemoveAll, setShowRemoveAll] = useState(false);

  return (
    <>
      <div className="relative isolate bg-white">
        <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
          <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
              <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-slate-50 lg:w-1/2"></div>
              <h2 className="text-3xl font-bold tracking-tight text-gray-900">Контакты</h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Остались вопросы? Свяжитесь с нашей{" "}
                <a href="mailto:toys@fast-track.pro" className="font-medium text-emerald-400 hover:text-emerald-300">
                  командой поддержки
                </a>{" "}
                или заполните форму.
              </p>
              <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Telephone</span>
                    <PhoneIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                  </dt>
                  <dd>
                    <a className="hover:text-gray-900" href="tel:+74958881367">
                      8 495 888-13-67
                    </a>
                    ,{" "}
                    <a className="hover:text-gray-900" href="tel:+74958884062">
                      8 495 888-40-62
                    </a>
                  </dd>
                </div>
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Telephone</span>
                    <ChatBubbleBottomCenterTextIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                  </dt>
                  <dd>
                    <a target="_blank" className="hover:text-gray-900" href="https://t.me/Alena_S_88">
                      Telegram
                    </a>
                  </dd>
                </div>
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Whatsapp</span>
                    <ChatBubbleOvalLeftEllipsisIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                  </dt>
                  <dd>
                    <a target="_blank" className="hover:text-gray-900" href="http://wa.me/+74958881367">
                      WhatsApp 1
                    </a>
                    ,{" "}
                    <a target="_blank" className="hover:text-gray-900" href="http://wa.me/+74958884062">
                      WhatsApp 2
                    </a>
                  </dd>
                </div>
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Email</span>
                    <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                  </dt>
                  <dd>
                    <a className="hover:text-gray-900" href="mailto:toys@fast-track.pro">
                      toys@fast-track.pro
                    </a>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <form className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48">
            <div className="mx-auto max-w-lg lg:max-w-none">
              <section aria-labelledby="payment-heading">
                <p className="text-sm text-slate-600">* обязательные поля для заполнения</p>

                <div className="mt-6 grid grid-cols-3 gap-y-6 gap-x-4 sm:grid-cols-4">
                  <div className="col-span-2">
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                      Имя *
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-span-2">
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                      Фамилия *
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        autoComplete="csc"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-span-3 sm:col-span-4">
                    <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                      Компания *
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="company"
                        name="company"
                        autoComplete="cc-number"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                        onChange={(e) => setCompany(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-span-2">
                    <label htmlFor="tel" className="block text-sm font-medium text-gray-700">
                      Телефон *
                    </label>
                    <div className="mt-1">
                      <input
                        type="tel"
                        name="tel"
                        id="tel"
                        onChange={(e) => setTel(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="col-span-2">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Почта *
                    </label>
                    <div className="mt-1">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        onChange={(e) => setEmail(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="col-span-3 sm:col-span-4">
                    <label htmlFor="message" className=" w-full text-sm font-medium text-gray-700">
                      Комментарий
                    </label>
                    <div className="mt-1 w-full">
                      <textarea
                        name="message"
                        id="message"
                        rows={3}
                        onChange={(e) => setMessage(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex items-center">
                  <input
                    id="terms"
                    name="terms"
                    type="checkbox"
                    defaultChecked
                    onChange={(e) => setTerms(e.target.checked)}
                    className="h-4 w-4 rounded border-gray-300 text-emerald-400 focus:ring-emerald-500"
                  />
                  <div className="ml-2">
                    <label htmlFor="terms" className="text-sm font-medium text-gray-900">
                      Принимаю{" "}
                      <a href="#" className="text-emerald-400 hover:text-emerald-500">
                        условия обработки данных
                      </a>
                    </label>
                  </div>
                </div>
              </section>

              <div className="mt-10 border-t border-gray-200 pt-6 sm:flex sm:items-center sm:justify-between">
                <a
                  className={clsx(
                    firstName && lastName && company && tel && email ? "" : "pointer-events-none opacity-60",
                    "w-full rounded-md border border-transparent bg-emerald-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last sm:ml-6 sm:w-auto"
                  )}
                  target="_blank"
                  href={`mailto:toys@fast-track.pro?subject=${company ?? ""} — оформить заказ&body=Имя: ${firstName ?? ""}%0D%0AФамилия: ${
                    lastName ?? ""
                  }%0D%0AКомпания: ${company ?? ""}%0D%0AТелефон: ${tel ?? ""}%0D%0AПочта: ${email ?? ""}%0D%0AКомменатрий: ${message ?? ""}`}
                >
                  Отправить
                </a>
                <p className="mt-4 text-center text-sm text-gray-500 sm:mt-0 sm:text-left">Менеджер свяжется с вами в течение 1 рабочего дня.</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
