/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import productsJson from "../../productsJson.json";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  XMarkIcon,
  ShoppingCartIcon,
  UserIcon,
  PlusIcon,
  CheckCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import CheckImage from "../../components/checkImage";
import clsx from "clsx";

function morph(int: number, array?: string[]) {
  return (array = array || ["товар", "товара", "товаров"]) && array[int % 100 > 4 && int % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][int % 10 < 5 ? int % 10 : 5]];
}

export default function Cart() {
  const [cart, setCart] = useState<any[]>([]);
  const [tempCart, setTempCart] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [company, setCompany] = useState<string>();
  const [tel, setTel] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [terms, setTerms] = useState<boolean>();
  const [showAdd, setShowAdd] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showRemoveAll, setShowRemoveAll] = useState(false);

  useEffect(() => {
    setCart(JSON.parse(window.localStorage.cart ?? "[]"));
    setTempCart(JSON.parse(window.localStorage.cart ?? "[]"));
  }, []);

  useEffect(() => {
    if (tempCart.length > 0) {
      setProducts(productsJson.filter((p) => cart.includes(p["Артикул"])));
    }
  }, [tempCart]);

  const getProductsString = () => {
    const stringArr = [];
  };

  const addToCart = (e: any, a: any) => {
    e.preventDefault();

    const newCart = [...cart, a];

    setShowAdd(true);
    setCart(newCart);

    try {
      window.localStorage.setItem("cart", JSON.stringify(newCart));
    } catch {}

    setTimeout(() => {
      setShowAdd(false);
    }, 2000);
  };

  const removeFromCart = (e: any, a: any) => {
    e.preventDefault();

    const newCart = cart.filter((item) => item !== a);

    setShowRemove(true);
    setCart(newCart);

    try {
      window.localStorage.setItem("cart", JSON.stringify(newCart));
    } catch {}

    setTimeout(() => {
      setShowRemove(false);
    }, 2000);
  };

  const clearCart = (e: any) => {
    e.preventDefault();

    const confirmClear = window.confirm("Вы уверены, что хотите удалить все товары из списка покупок?");
    const newCart: any[] = [];

    if (confirmClear) {
      setShowRemoveAll(true);
      setCart(newCart);

      try {
        window.localStorage.setItem("cart", JSON.stringify(newCart));
      } catch {}

      setTimeout(() => {
        setShowRemoveAll(false);
      }, 2000);
    }
  };

  return (
    <>
      <div className="bg-white">
        {/* Background color split screen for large screens */}
        <div className="fixed top-0 left-0 hidden h-full w-1/2 bg-white lg:block" aria-hidden="true" />
        <div className="fixed top-0 right-0 hidden h-full w-1/2 bg-gray-50 lg:block" aria-hidden="true" />

        <div className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 xl:gap-x-48">
          <h1 className="sr-only">Order information</h1>

          <section
            aria-labelledby="summary-heading"
            className="bg-gray-50 px-4 pt-16 pb-10 sm:px-6 lg:col-start-2 lg:row-start-1 lg:bg-transparent lg:px-0 lg:pb-16"
          >
            <div className="mx-auto max-w-lg lg:max-w-none">
              <div className="flex items-center justify-between">
                <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
                  Список покупок
                </h2>
                <a href="#" className="text-sm text-slate-500" onClick={clearCart}>
                  Очистить список покупок
                </a>
              </div>

              <ul role="list" className="divide-y divide-gray-200 text-sm font-medium text-gray-900">
                {products.map((product) => (
                  <li key={product["Артикул"]} className="flex items-start space-x-4 py-6">
                    <Link to={`/product/${product["Артикул"]}`}>
                      <CheckImage
                        src={`/assets/i/${product["Артикул"]}.jpg`}
                        alt={product["Наименование"]}
                        className="h-20 w-20 flex-none rounded-md object-cover object-center hover:opacity-60"
                      />
                    </Link>

                    <div className="flex-auto space-y-1">
                      <h3>{product["Наименование"]}</h3>
                      <p className="text-gray-500">{product["Серия"]}</p>
                    </div>
                    {cart.includes(product["Артикул"]) ? (
                      <button
                        type="button"
                        className="flex items-center rounded-full border border-transparent bg-slate-400 p-3 text-white shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2"
                        onClick={(e: any) => removeFromCart(e, product["Артикул"])}
                      >
                        <TrashIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="flex items-center rounded-full border border-transparent bg-emerald-400 p-3 text-white shadow-sm hover:bg-emerald-500 focus:outline-none focus:ring-2 focus:ring-emerald-400 focus:ring-offset-2"
                        onClick={(e: any) => addToCart(e, product["Артикул"])}
                      >
                        <PlusIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    )}
                  </li>
                ))}
              </ul>

              <dl className="spt-6 hidden text-sm font-medium text-gray-900 lg:block">
                <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                  <dt className="text-base">Всего</dt>
                  <dd className="text-base">
                    {cart.length} {morph(cart.length)}
                  </dd>
                </div>
              </dl>

              <Popover className="fixed inset-x-0 bottom-0 flex flex-col-reverse text-sm font-medium text-gray-900 lg:hidden">
                <div className="relative z-10 border-t border-gray-200 bg-white px-4 sm:px-6">
                  <div className="mx-auto max-w-lg">
                    <Popover.Button className="flex w-full items-center py-6 font-medium">
                      <span className="mr-auto text-base">Всего</span>
                      <span className="mr-2 text-base">{products.length}</span>
                      <ChevronUpIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>

                <Transition.Root as={Fragment}>
                  <div>
                    <Transition.Child
                      as={Fragment}
                      enter="transition-opacity ease-linear duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-opacity ease-linear duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Popover.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <Transition.Child
                      as={Fragment}
                      enter="transition ease-in-out duration-300 transform"
                      enterFrom="translate-y-full"
                      enterTo="translate-y-0"
                      leave="transition ease-in-out duration-300 transform"
                      leaveFrom="translate-y-0"
                      leaveTo="translate-y-full"
                    >
                      <Popover.Panel className="relative bg-white px-4 py-6 sm:px-6">
                        <dl className="mx-auto max-w-lg space-y-6">
                          <div className="flex items-center justify-between">
                            <dt className="text-gray-600">Subtotal</dt>
                            <dd>$320.00</dd>
                          </div>

                          <div className="flex items-center justify-between">
                            <dt className="text-gray-600">Shipping</dt>
                            <dd>$15.00</dd>
                          </div>

                          <div className="flex items-center justify-between">
                            <dt className="text-gray-600">Taxes</dt>
                            <dd>$26.80</dd>
                          </div>
                        </dl>
                      </Popover.Panel>
                    </Transition.Child>
                  </div>
                </Transition.Root>
              </Popover>
            </div>
          </section>

          <form className="px-4 pt-16 pb-36 sm:px-6 lg:col-start-1 lg:row-start-1 lg:px-0 lg:pb-16">
            <div className="mx-auto max-w-lg lg:max-w-none">
              <section aria-labelledby="payment-heading">
                <h2 id="payment-heading" className="text-lg font-medium text-gray-900">
                  Оформить заказ
                </h2>
                <p className="text-sm text-slate-600">* обязательные поля для заполнения</p>

                <div className="mt-6 grid grid-cols-3 gap-y-6 gap-x-4 sm:grid-cols-4">
                  <div className="col-span-2">
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                      Имя *
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-span-2">
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                      Фамилия *
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        autoComplete="csc"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-span-3 sm:col-span-4">
                    <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                      Компания *
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="company"
                        name="company"
                        autoComplete="cc-number"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                        onChange={(e) => setCompany(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-span-2">
                    <label htmlFor="tel" className="block text-sm font-medium text-gray-700">
                      Телефон *
                    </label>
                    <div className="mt-1">
                      <input
                        type="tel"
                        name="tel"
                        id="tel"
                        onChange={(e) => setTel(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="col-span-2">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Почта *
                    </label>
                    <div className="mt-1">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        onChange={(e) => setEmail(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="col-span-3 sm:col-span-4">
                    <label htmlFor="message" className=" w-full text-sm font-medium text-gray-700">
                      Комментарий
                    </label>
                    <div className="mt-1 w-full">
                      <textarea
                        name="message"
                        id="message"
                        rows={3}
                        onChange={(e) => setMessage(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex items-center">
                  <input
                    id="terms"
                    name="terms"
                    type="checkbox"
                    defaultChecked
                    onChange={(e) => setTerms(e.target.checked)}
                    className="h-4 w-4 rounded border-gray-300 text-emerald-400 focus:ring-emerald-500"
                  />
                  <div className="ml-2">
                    <label htmlFor="terms" className="text-sm font-medium text-gray-900">
                      Принимаю{" "}
                      <a href="#" className="text-emerald-400 hover:text-emerald-500">
                        условия обработки данных
                      </a>
                    </label>
                  </div>
                </div>
              </section>

              <div className="mt-10 border-t border-gray-200 pt-6 sm:flex sm:items-center sm:justify-between">
                <a
                  className={clsx(
                    firstName && lastName && company && tel && email ? "" : "pointer-events-none opacity-60",
                    "w-full rounded-md border border-transparent bg-emerald-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last sm:ml-6 sm:w-auto"
                  )}
                  target="_blank"
                  href={`mailto:toys@fast-track.pro?subject=${company ?? ""} — оформить заказ&body=Имя: ${firstName ?? ""}%0D%0AФамилия: ${
                    lastName ?? ""
                  }%0D%0AКомпания: ${company ?? ""}%0D%0AТелефон: ${tel ?? ""}%0D%0AПочта: ${email ?? ""}%0D%0AКомменатрий: ${
                    message ?? ""
                  }%0D%0A%0D%0AСписок покупок:%0D%0A%0D%0A${products
                    .map((i: any) => {
                      let o: any = {};
                      o["Артикул"] = i["Артикул"];
                      o["Наименование"] = i["Наименование"];
                      o["Серия"] = i["Серия"];

                      return o;
                    })
                    .map((i) => JSON.stringify(i))
                    .join("%0D%0A")}`
                    .replace(/["{]/g, "")
                    .replace(/\,/g, "%0D%0A")
                    .replace(/\}/g, "%0D%0A")
                    .replace(/\:/g, ": ")}
                >
                  Отправить
                </a>
                <p className="mt-4 text-center text-sm text-gray-500 sm:mt-0 sm:text-left">Менеджер свяжется с вами в течение 1 рабочего дня.</p>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Global notification live region, render this permanently at the end of the document */}
      <div aria-live="assertive" className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6">
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={showAdd}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">Готово!</p>
                    <p className="mt-1 text-sm text-gray-500">Товар добавлен в список покупок.</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                      onClick={() => {
                        setShowAdd(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>

      {/* Global notification live region, render this permanently at the end of the document */}
      <div aria-live="assertive" className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6">
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={showRemove}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">Готово!</p>
                    <p className="mt-1 text-sm text-gray-500">Товар удален из списка покупок.</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                      onClick={() => {
                        setShowRemove(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>

      {/* Global notification live region, render this permanently at the end of the document */}
      <div aria-live="assertive" className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6">
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={showRemoveAll}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">Готово!</p>
                    <p className="mt-1 text-sm text-gray-500">Товары удалены из списка покупок.</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                      onClick={() => {
                        setShowRemoveAll(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
