import { IRoute } from "./interfaces/routes";

export const copyTextToClipboard = (text: string) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
};

const fallbackCopyTextToClipboard = (text: string) => {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
};

export const selectText = (element: string) => {
  const node = document.getElementById(element);

  if (node) {
    if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(node);
      selection?.removeAllRanges();
      selection?.addRange(range);
    } else {
      console.warn("Could not select text in node: Unsupported browser.");
    }
  }
};

export const combinePaths = (parent: string = "", child: string = "") => `${parent.replace(/\/$/, "")}/${child.replace(/^\//, "")}`;

export const buildPaths = (navigation: any[], parentPath: string = ""): IRoute[] =>
  navigation.map((route: IRoute) => {
    const path = combinePaths(parentPath, route.path);
    const component = route.component;
    const name = route.name;
    const isPrivate = route.isPrivate;
    const layout = route.layout;
    const parent = route.parent;

    return {
      path,
      component,
      name,
      isPrivate,
      layout,
      parent,
      ...(route.routes && { routes: buildPaths(route.routes, path) }),
    };
  });

export const flattenRoutes = (routes: IRoute[]): any => routes.map((route) => [route.routes ? flattenRoutes(route.routes) : [], route]).flat(Infinity);

export const setupParents = (routes: IRoute[], parentRoute: any = null): IRoute[] =>
  routes.map((route: IRoute) => {
    const withParent = {
      ...route,
      ...(parentRoute && { parent: parentRoute }),
    };

    return {
      ...withParent,
      ...(withParent.routes && {
        routes: setupParents(withParent.routes, withParent),
      }),
    };
  });

export const pathTo = (route: IRoute): IRoute[] => {
  if (!route.parent) {
    return [route];
  }

  return [...pathTo(route.parent), route];
};

export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
