import { Fragment, useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Disclosure, RadioGroup, Tab } from "@headlessui/react";
import { StarIcon } from "@heroicons/react/20/solid";
import { HeartIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";
import productsJson from "../../productsJson.json";
import { Link, useLocation, useHistory } from "react-router-dom";
import CheckImage from "../../components/checkImage";
import dayjs from "dayjs";
import "dayjs/locale/ru";

dayjs.locale("ru");

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Product() {
  const id = useParams<{ productId: string }>().productId;
  const [product, setProduct] = useState<any>();
  const [show, setShow] = useState(false);
  const [isInCart, setIsInCart] = useState(false);
  const [toastText, setToastText] = useState("Товар добавлен в список покупок.");
  const [cart, setCart] = useState<any[]>([]);
  const [details, setDetails] = useState<any[]>([
    {
      name: "Доставка",
      items: ["забрать с нашего склада", "до двери"],
    },
    {
      name: "Возврат",
      items: ["в теченеи 14 дней"],
    },
    // More sections...
  ]);

  const history = useHistory();

  useEffect(() => {
    const p = productsJson.filter((o: any) => o["Артикул"] === id);

    if (p.length > 0) {
      setProduct(p[0]);
    }
  }, []);

  useEffect(() => {
    setCart(JSON.parse(window.localStorage.cart ?? "[]"));
  }, []);

  useEffect(() => {
    if (product && product["Дата запуска"]) {
      const localeDate = dayjs(product["Дата запуска"], "DD-MM-YY")?.locale("ru-ru").format("MMMM D, YYYY");

      const d = [
        {
          name: "Дата запуска",
          items: [localeDate],
        },
        {
          name: "Доставка",
          items: ["забрать с нашего склада", "до двери"],
        },
        {
          name: "Возврат",
          items: ["в теченеи 14 дней"],
        },
        // More sections...
      ];

      setDetails(d);
    }
  }, [product]);

  useEffect(() => {
    if (!!product) {
      const newIsInCartIncluded = cart.includes(product["Артикул"]);
      setIsInCart(newIsInCartIncluded);
      setToastText(newIsInCartIncluded ? "Товар добавлен в список покупок." : "Товар удален из списка покупок.");
    }
  }, [product, cart]);

  const addToCart = (e: any) => {
    e.preventDefault();

    const newCart = [...cart, product["Артикул"]];

    setShow(true);
    setIsInCart(true);
    setCart(newCart);

    try {
      window.localStorage.setItem("cart", JSON.stringify(newCart));
    } catch {}

    setTimeout(() => {
      setShow(false);

      setTimeout(() => {
        setToastText("Товар удален из корзины.");
      }, 500);
    }, 2000);
  };

  const removeFromCart = (e: any) => {
    e.preventDefault();

    const newCart = cart.filter((item) => item !== product["Артикул"]);

    setShow(true);
    setIsInCart(false);
    setCart(newCart);

    try {
      window.localStorage.setItem("cart", JSON.stringify(newCart));
    } catch {}

    setTimeout(() => {
      setShow(false);

      setTimeout(() => {
        setToastText("Товар добавлен из корзину.");
      }, 500);
    }, 2000);
  };

  const goToSeries = (series: string) => {
    const newActiveFilters = [{ value: "Серия", label: series }];

    try {
      window.localStorage.setItem("activeFilters", JSON.stringify(newActiveFilters));
    } catch {}

    history.goBack();
  };

  return (
    <>
      <div className="bg-white">
        <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
          {product && (
            <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
              {/* Image gallery */}
              <Tab.Group as="div" className="flex flex-col-reverse">
                {/* Image selector */}
                <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
                  <Tab.List className="grid grid-cols-4 gap-6">
                    {[0, 1, 2, 3].map((item, i) => (
                      <Tab
                        key={i}
                        className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 "
                      >
                        {({ selected }) => (
                          <>
                            <span className="absolute inset-0 overflow-hidden rounded-md focus:outline-none ">
                              <CheckImage
                                src={i === 0 ? `/assets/i/${product["Артикул"]}.jpg` : `/assets/i/${product["Артикул"]}_${i}.jpg`}
                                alt={product["Наименование"]}
                                className="h-full w-full object-contain object-center"
                              />
                            </span>
                            <span
                              className={classNames(
                                selected ? "ring-emerald-500" : "ring-transparent",
                                "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                </div>

                <Tab.Panels className="aspect-w-1 aspect-h-1 w-full">
                  {[0, 1, 2, 3].map((item, i) => (
                    <Tab.Panel key={i}>
                      <CheckImage
                        src={i === 0 ? `/assets/i/${product["Артикул"]}.jpg` : `/assets/i/${product["Артикул"]}_${i}.jpg`}
                        alt={product["Наименование"]}
                        className="h-full w-full object-contain object-center  sm:rounded-lg"
                      />
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>

              {/* Product info */}
              <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900">{product["Наименование"]}</h1>

                <div className="mt-3">
                  <h2 className="sr-only">Product information</h2>
                  <a href="#" onClick={() => goToSeries(product["Серия"])} className="text-3xl tracking-tight text-gray-600 hover:text-gray-900">
                    {product["Серия"]}
                  </a>
                </div>

                <div className="mt-6">
                  <h3 className="sr-only">Description</h3>

                  <div className="space-y-6 text-sm text-slate-500">{product["Описание"] === "0" ? product["Артикул"] : product["Описание"]}</div>
                </div>

                <form className="mt-6">
                  <div className="sm:flex-col1 mt-10 flex">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                      onClick={() => history.goBack()}
                    >
                      Назад
                    </button>

                    {isInCart ? (
                      <button
                        className="ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                        onClick={(e) => removeFromCart(e)}
                      >
                        Удалить из списка покупок
                      </button>
                    ) : (
                      <button
                        className="ml-3 inline-flex items-center rounded-md border border-transparent bg-emerald-400  px-4 py-2 text-base font-medium text-white hover:bg-emerald-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                        onClick={(e) => addToCart(e)}
                      >
                        Добавить в список покупок
                      </button>
                    )}
                  </div>
                </form>

                <section aria-labelledby="details-heading" className="mt-12">
                  <h2 id="details-heading" className="sr-only">
                    Дополнительная информация
                  </h2>

                  <div className="divide-y divide-gray-200 border-t">
                    {details?.map((detail) => (
                      <Disclosure as="div" key={detail.name}>
                        {({ open }) => (
                          <>
                            <h3>
                              <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                                <span className={classNames(open ? "text-emerald-400" : "text-gray-900", "text-sm font-medium")}>{detail.name}</span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <MinusIcon className="block h-6 w-6 text-emerald-400 group-hover:text-emerald-500" aria-hidden="true" />
                                  ) : (
                                    <PlusIcon className="block h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel as="div" className="prose prose-sm pb-6">
                              <ul role="list">
                                {Array.isArray(detail.items) &&
                                  detail.items?.map((item: any, i: any) => (
                                    <li className="capitalize" key={i}>
                                      {item}
                                    </li>
                                  ))}
                              </ul>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </div>
                </section>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Global notification live region, render this permanently at the end of the document */}
      <div aria-live="assertive" className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6">
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">Готово!</p>
                    <p className="mt-1 text-sm text-gray-500">{toastText}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
