/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { Fragment, useState } from "react";
import { Dialog, Disclosure, Menu, Popover, Tab, Transition } from "@headlessui/react";
import { Bars3Icon, MagnifyingGlassIcon, ShoppingBagIcon, XMarkIcon, ShoppingCartIcon, UserIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { ChevronDownIcon, CheckBadgeIcon, SparklesIcon } from "@heroicons/react/20/solid";
import Products from "../../components/products";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";

import { LifebuoyIcon, NewspaperIcon, PhoneIcon } from "@heroicons/react/20/solid";

const callouts = [
  {
    name: "Baby Alive",
    href: "#",
    imageSrc: "/assets/img/6e6a03d5c0e03131f1cea33436bd7cd0.png",
    imageAlt: "Baby Alive",
  },
  {
    name: "GI Joe",
    href: "#",
    imageSrc: "/assets/img/6e6a03d5c0e03131f1cea33436bd7cd0.png",
    imageAlt: "GI Joe",
  },
  {
    name: "My Little Pony",
    href: "#",
    imageSrc: "/assets/img/6e6a03d5c0e03131f1cea33436bd7cd0.png",
    imageAlt: "My Little Pony",
  },
  {
    name: "Play-Doh",
    href: "#",
    imageSrc: "/assets/img/6e6a03d5c0e03131f1cea33436bd7cd0.png",
    imageAlt: "Play-Doh",
  },
  {
    name: "Star Wars",
    href: "#",
    imageSrc: "/assets/img/6e6a03d5c0e03131f1cea33436bd7cd0.png",
    imageAlt: "Star Wars",
  },
  {
    name: "Monopoly & Hasbro Gaming",
    href: "#",
    imageSrc: "/assets/img/6e6a03d5c0e03131f1cea33436bd7cd0.png",
    imageAlt: "Monopoly & Hasbro Gaming",
  },
  {
    name: "Nerf",
    href: "#",
    imageSrc: "/assets/img/6e6a03d5c0e03131f1cea33436bd7cd0.png",
    imageAlt: "Nerf",
  },
  {
    name: "Playskool",
    href: "#",
    imageSrc: "/assets/img/6e6a03d5c0e03131f1cea33436bd7cd0.png",
    imageAlt: "Playskool",
  },
  {
    name: "Marvel",
    href: "#",
    imageSrc: "/assets/img/6e6a03d5c0e03131f1cea33436bd7cd0.png",
    imageAlt: "Marvel",
  },
  {
    name: "Transformers",
    href: "#",
    imageSrc: "/assets/img/6e6a03d5c0e03131f1cea33436bd7cd0.png",
    imageAlt: "Transformers",
  },
  {
    name: "Furreal",
    href: "#",
    imageSrc: "/assets/img/6e6a03d5c0e03131f1cea33436bd7cd0.png",
    imageAlt: "Furreal",
  },
];

const features = [
  {
    name: "О компании.",
    description:
      "Hasbro развивает свои продукты по всему миру, создавая интересные истории и контент, в том числе благодаря принадлежащим ей кинокомпании Allspark Pictures и студии анимации Allspark Animation. Также Hasbro уделяет большое внимание корпоративной социальной ответственности и филантропии, тем самым делая этот мир лучше для детей и их родителей. Компания была основана в 1923 году братьями Хассенфельдами.",
    icon: CheckBadgeIcon,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Hasbro() {
  const [open, setOpen] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [propsActiveFilter, setPropsActiveFiter] = useState<any>();
  const [openCallouts, setOpenCallouts] = useState(false);

  const addPropsActiveFilter = (value: string) => {
    const newPropsActiveFilter = { section: "Серия", value };

    setPropsActiveFiter(newPropsActiveFilter);
  };

  return (
    <div className="bg-white">
      <div className="overflow-hidden bg-white pt-24 sm:pt-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:ml-auto lg:pt-4 lg:pl-4">
              <div className="lg:max-w-lg">
                <img src="/assets/img/Hasbro-Logo.png" className="mb-6 -ml-10 h-[100px]" alt="" />

                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Hasbro</p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Hasbro – один из лидеров индустрии развлечений, международная компания, миссия которой – создавать незабываемые впечатления от игры. С помощью
                  игрушек, настольных игр, телевизионных программ, полнометражных фильмов, компьютерных игр и лицензионных товаров Hasbro предоставляет
                  потребителям самые разнообразные возможности познакомиться со своими культовыми брендами, такими как NERF, MY LITTLE PONY, TRANSFORMERS,
                  PLAY-DOH, MONOPOLY, BABY ALIVE и MAGIC: THE GATHERING, а также премиальными партнерскими брендами.
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon className="absolute top-1 left-1 h-5 w-5 text-cyan-600" aria-hidden="true" />
                        {feature.name}
                      </dt>{" "}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <div className="flex items-start justify-end lg:order-first">
              <img
                src="/assets/img/C8fLTfAW0AA2XAQ.jpg"
                alt="Product screenshot"
                className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                width={2432}
                height={1442}
              />
            </div>
          </div>
        </div>
      </div>

      <main>
        {/* Category section */}
        <div className="mt-10">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-2xl py-16 lg:max-w-none">
              <div className="sm:flex sm:items-baseline sm:justify-between">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900">Серии Hasbro</h2>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-y-4 sm:grid-cols-6 sm:gap-x-3 sm:gap-y-4">
                {callouts.slice(0, openCallouts ? callouts.length : 6).map((series) => (
                  <a
                    key={series.name}
                    href="#products"
                    onClick={() => addPropsActiveFilter(series.name)}
                    className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
                  >
                    <div className="bg-gray-200 group-hover:opacity-75">
                      <img src={series.imageSrc} alt={series.imageAlt} className="h-full w-full object-cover object-center sm:h-full sm:w-full" />
                    </div>

                    <div className="flex flex-1 flex-col space-y-2 p-4">
                      <h3 className="text-sm font-medium text-gray-900">
                        <span aria-hidden="true" className="absolute inset-0" />
                        {series.name}
                      </h3>
                      <p className="text-sm text-gray-500">LEGO</p>
                    </div>
                  </a>
                ))}
              </div>

              {callouts.length > 6 && (
                <div className="mt-8 flex justify-center">
                  {openCallouts ? (
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-400 focus:ring-offset-2"
                      onClick={() => setOpenCallouts(false)}
                    >
                      Скрыть
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-400 focus:ring-offset-2"
                      onClick={() => setOpenCallouts(true)}
                    >
                      Показать все
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Favorites section */}
        <Products propsActiveFilter={propsActiveFilter} />

        {/* CTA section */}
        <section aria-labelledby="sale-heading">
          <div className="overflow-hidden pt-32 sm:pt-14">
            <div className="bg-gray-100">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="relative pt-48 pb-16 sm:pb-24">
                  <div>
                    <h2 id="sale-heading" className="text-5xl tracking-tight text-gray-900">
                      Индивидуальный подход <br /> к каждому клиенту
                      <br />
                    </h2>
                    <p className="mt-4 block text-2xl font-normal">Гибкие условия сотрудничества.</p>
                    <div className="mt-6 text-base">
                      <Link
                        to="/cart"
                        className="inline-block rounded-md border border-transparent bg-emerald-400 py-3 px-8 text-center font-medium text-white hover:bg-emerald-600"
                      >
                        Сделать заказ
                      </Link>
                    </div>
                  </div>

                  <div className="absolute -top-32 left-1/2 -translate-x-1/2 transform sm:top-6 sm:translate-x-0">
                    <div className="ml-24 flex min-w-max space-x-6 sm:ml-3 lg:space-x-8">
                      <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                        <div className="flex-shrink-0">
                          <img className="h-64 w-64 rounded-lg object-cover md:h-96 md:w-72" src="/assets/img/21184_2.jpg" alt="" />
                        </div>

                        <div className="mt-6 flex-shrink-0 sm:mt-0">
                          <img className="h-64 w-64 rounded-lg object-cover md:h-96 md:w-72" src="/assets/img/21170_2.jpg" alt="" />
                        </div>
                      </div>
                      <div className="flex space-x-6 sm:-mt-20 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                        <div className="flex-shrink-0">
                          <img className="h-64 w-64 rounded-lg object-cover md:h-96 md:w-72" src="/assets/img/31114_10.jpg" alt="" />
                        </div>

                        <div className="mt-6 flex-shrink-0 sm:mt-0">
                          <img className="h-64 w-64 rounded-lg object-cover md:h-96 md:w-72" src="/assets/img/31127_2.jpg" alt="" />
                        </div>
                      </div>
                      <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                        <div className="flex-shrink-0">
                          <img className="h-64 w-64 rounded-lg object-cover md:h-96 md:w-72" src="/assets/img/21331_7.jpg" alt="" />
                        </div>

                        <div className="mt-6 flex-shrink-0 sm:mt-0">
                          <img className="h-64 w-64 rounded-lg object-cover md:h-96 md:w-72" src="/assets/img/31058_2.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
