/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { Fragment, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "swiper/swiper.min.css";

const features = [
  {
    name: "LEGO",
    description:
      'Название LEGO - это аббревиатура двух датских слов "leg godt", что означает "хорошо играть". Группа LEGO была основана в 1932 году Оле Кирком Кристиансеном. Компания переходила от отца к сыну и сейчас принадлежит Кьельду Кирку Кристиансену, внуку основателя.',
    imageSrc: "/assets/img/76951_WEB_PRI.webp",
    imageAlt: "",
    value: "LEGO",
  },
  {
    name: "Mattel",
    description:
      "Mattel - ведущая мировая компания по производству игрушек и владелец одного из самых сильных портфелей детских и семейных развлекательных франшиз в мире. Их миссия заключается в создании инновационных продуктов и впечатлений, которые вдохновляют, развлекают и развивают детей через игру. Mattel, Inc. – американская транснациональная компания по производству игрушек, основанная в 1945 со штаб-квартирой в Эль-Сегундо, Калифорния (El Segundo, California). Была основана в 1945 году Эллиотом и Рут Хэндлер.",
    imageSrc: "/assets/img/1132805.jpg",
    imageAlt: "",
    value: "mattel",
  },
  {
    name: "Hasbro",
    description:
      "Hasbro развивает свои продукты по всему миру, создавая интересные истории и контент, в том числе благодаря принадлежащим ей кинокомпании Allspark Pictures и студии анимации Allspark Animation. Также Hasbro уделяет большое внимание корпоративной социальной ответственности и филантропии, тем самым делая этот мир лучше для детей и их родителей. Компания была основана в 1923 году братьями Хассенфельдами.",
    imageSrc: "/assets/img/C8fLTfAW0AA2XAQ.jpg",
    imageAlt: "",
    value: "hasbro",
  },
];

export default function Kids() {
  const [open, setOpen] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [propsActiveFilter, setPropsActiveFiter] = useState<any>();
  const history = useHistory();

  const addPropsActiveFilter = (value: string) => {
    const newPropsActiveFilter = { section: "Серия", value };

    setPropsActiveFiter(newPropsActiveFilter);
  };

  const goToBrand = (brand: string) => {
    const newActiveFilters = [{ value: "Бренд", label: brand }];

    try {
      window.localStorage.setItem("activeFilters", JSON.stringify(newActiveFilters));
    } catch {}

    history.push(`/kids/${brand}`);
  };

  return (
    <>
      <div className="bg-white">
        <div className="mx-auto max-w-7xl py-24 sm:px-2 sm:py-32 lg:px-4">
          <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
            <div className="max-w-3xl">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Игрушки</p>
              <p className="mt-4 text-gray-500">Большая коллекция игрушек от мировых брендов.</p>
            </div>

            <div className="mt-10 space-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16">
              {features.map((feature) => (
                <div key={feature.name} className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8">
                  <div className="mt-6 lg:col-span-5 lg:mt-0 xl:col-span-4">
                    <h3 className="text-lg font-medium text-gray-900">{feature.name}</h3>
                    <p className="mt-2 text-sm text-gray-500 line-clamp-4">{feature.description}</p>
                    <div className="mt-6">
                      <button
                        type="button"
                        onClick={() => goToBrand(feature.value)}
                        className="inline-block rounded-md border border-transparent bg-emerald-400 py-3 px-8 font-medium text-white hover:bg-emerald-500"
                      >
                        Посмотреть
                      </button>
                    </div>
                  </div>
                  <div className="flex-auto lg:col-span-7 xl:col-span-8">
                    <div
                      onClick={() => goToBrand(feature.value)}
                      className="aspect-h-2 aspect-w-5 cursor-pointer overflow-hidden rounded-lg bg-gray-100 hover:opacity-60"
                    >
                      <img src={feature.imageSrc} alt={feature.imageAlt} className="object-cover object-center" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
