const faqs = [
  {
    id: 1,
    question: "Что означают гибкие условия?",
    answer:
      "Индивидуальный подход к каждому клиенту. Мы рады предложить вам грамотную консультацию и возможность приобрести товар на разных условиях сотрудничества.",
  },
  {
    id: 2,
    question: "Как сделать заказ?",
    answer:
      "Найдите нужный товар в каталоге. Добавьте его в корзину, нажав кнопку «Заказать» (если у товара указана цена, если нет – запросите коммерческое предложение с индивидуальными условиями по цене, оплате и доставке, позвонив нам). Выберите тип получения заказа, при необходимости оставьте комментарий. Если в процессе оформления заказа возникли вопросы или вы не нашли интересующий вас товар, вы можете оставить заявку на сайте, наши специалисты свяжутся с вами по телефону, мессенджеру или электронной почте.",
  },
  {
    id: 3,
    question: "Почему нам можно доверять?",
    answer:
      "Вы можете быть уверены в качестве предоставляемых услуг. Наши сотрудники постоянно проводят мониторинг зарубежных производителей и отбирают товары с наиболее выгодными ценовыми предложениями. Кроме этого, мы эксперты в логистике и работе с таможенными службами. Среди наших клиентов крупнейшие компании РФ.",
  },
  // More questions...
];

export default function FAQ() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-16 px-6 lg:py-20 lg:px-8">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">Часто задаваемые вопросы</h2>
            <p className="mt-4 text-lg text-gray-500">
              Не нашли ответа на свой вопрос? Свяжитесь с нашей{" "}
              <a href="mailto:toys@fast-track.pro" className="font-medium text-emerald-400 hover:text-emerald-300">
                командой поддержки
              </a>
              .
            </p>
          </div>
          <div className="mt-12 lg:col-span-2 lg:mt-0">
            <dl className="space-y-12">
              {faqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-lg font-medium leading-6 text-gray-900">{faq.question}</dt>
                  <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
