/* -------------------------------- 

Title: React App with Nested Routes and Breadcrumbs
Article: https://dev.to/sneas/react-app-with-nested-routes-and-breadcrumbs-90l

-------------------------------- */

import Home from "./routes/pages/home";
import ProductId from "./routes/pages/productId";
import Cart from "./routes/pages/cart";
import Status from "./routes/pages/status";
import Checkout from "./routes/pages/checkout";
import FAQ from "./routes/pages/faq";
import Kids from "./routes/pages/kids";
import Lego from "./routes/pages/lego";
import Mattel from "./routes/pages/mattel";
import Hasbro from "./routes/pages/hasbro";
import Login from "./routes/auth/login";
import SignUp from "./routes/auth/signUp";
import ForgotPassword from "./routes/auth/forgotPassword";
import ResetPassword from "./routes/auth/resetPassword";
import NoMatch from "./routes/pages/404";
import News from "./routes/pages/news";
import Contact from "./routes/pages/contact";

export const routes = [
  {
    path: "/",
    component: Home,
    routes: [
      {
        path: "/product",
        component: NoMatch,
        routes: [
          {
            path: "/:productId",
            component: ProductId,
          },
        ],
      },
      {
        path: "/cart",
        component: Cart,
      },
      {
        path: "/contact",
        component: Contact,
      },
      {
        path: "/checkout",
        component: Checkout,
      },
      {
        path: "/status",
        component: Status,
      },
      {
        path: "/new",
        component: News,
      },
      {
        path: "/faq",
        component: FAQ,
      },
      {
        path: "/kids",
        component: Kids,
        routes: [
          {
            path: "/lego",
            component: Lego,
          },
          {
            path: "/mattel",
            component: Mattel,
          },
          {
            path: "/hasbro",
            component: Hasbro,
          },
        ],
      },
      {
        path: "/login",
        component: Login,
      },
      {
        path: "/signup",
        component: SignUp,
      },
      {
        path: "/forgotpassword",
        component: ForgotPassword,
      },
      {
        path: "/resetpassword",
        component: ResetPassword,
      },
    ],
  },
];
