import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function CdFramework() {
  const { pathname } = useLocation();

  useEffect(() => {
    let cd = document.createElement("script");

    cd.src = "/assets/js/scripts.min.js"; // 👈 make sure to use the correct path
    cd.id = "cd-framework-js";
    document.body.appendChild(cd);

    return () => document.getElementById("cd-framework-js")!.remove();
  }, [pathname]);

  return null;
}
