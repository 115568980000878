import { IRoute } from "./interfaces/routes";
import Breadcrumb from "./components/breadcrumb";

export default function Page(props: any) {
  const { route, isLoading } = props;

  return (
    <>
      {/* start main content */}
      <route.component />
      {/* end main content */}
    </>
  );
}
